import { useDispatch, useSelector } from 'react-redux'
import FinancialInformationForm from './FinancialInformationForm'
import BankDataValidationForm from './BankDataValidationForm'
import React, { useEffect } from 'react'
import {
    getAlert,
    getBankData,
    getBankDataErrors,
    getBanksOptionsSelector,
    getHasFilledShippingAddress, getVerified
} from '../../../../store/login/reducers'
import { getBanksOptions, getBankValidationInfo } from '../../../../store/login/actions'
import Alerts from '../../../alerts/Alerts'
import { defineMessages } from 'react-intl'

defineMessages({
    NO_DATA: {
        id: 'NO_DATA',
        description: 'NO_DATA',
        defaultMessage: 'No data, validate to operate'
    },
    WAITING_FOR_DEPOSIT: {
        id: 'WAITING_FOR_DEPOSIT',
        description: 'WAITING_FOR_DEPOSIT',
        defaultMessage: 'Thank you for your data, we will send you 2 ingress to your bank with different amounts in the upcoming 24-48 hours so you can validate your bank data once its done on this same screen'
    },
    PENDING_VALIDATION: {
        id: 'PENDING_VALIDATION',
        description: 'PENDING_VALIDATION',
        defaultMessage: 'Pending of validation'
    },
    VALIDATED: {
        id: 'VALIDATED',
        description: 'VALIDATED',
        defaultMessage: 'Your account is validated'
    },
    NOT_VALIDATED: {
        id: 'NOT_VALIDATED',
        description: 'NOT_VALIDATED',
        defaultMessage: 'Your account is not validated, contact with administrator'
    }
})

const FinancialInformationContainer = () => {
    const dispatch = useDispatch()
    const bankData = useSelector(state => getBankData(state))
    const bankDataErrors = useSelector(state => getBankDataErrors(state))
    const banksOptions = useSelector(state => getBanksOptionsSelector(state))
    const hasFilledShippingAddress = useSelector((state) => getHasFilledShippingAddress(state))

    const bakDataAttempts = bankData?.attempts

    useEffect(() => {
        dispatch(getBankValidationInfo())
    }, [bakDataAttempts])

    useEffect(() => {
        dispatch(getBanksOptions())
        }, [])

    const validationState = {
        NO_DATA: 'NO_DATA',
        WAITING_FOR_DEPOSIT: 'WAITING_FOR_DEPOSIT',
        PENDING_VALIDATION: 'PENDING_VALIDATION',
        VALIDATED: 'VALIDATED',
        NOT_VALIDATED: 'NOT_VALIDATED'
    }

    let currentState = validationState.NO_DATA

    let validationAlert = <Alerts
        status='info'
        alert={'NO_DATA'}
    ></Alerts>

    // Hay datos pero miguel no ha hecho el ingreso
    if (bankData &&
        bankData.deposit1 === '0.00' &&
        bankData.deposit2 === '0.00' &&
        bankData.attempts < 3 &&
        bankData.validated === false) {
        currentState = validationState.WAITING_FOR_DEPOSIT
        validationAlert = <Alerts
            status='warning'
            alert={'WAITING_FOR_DEPOSIT'}
        ></Alerts>
    }

    // Miguel ha hecho el ingreso pero el usuario no ha hecho la validación
    if (bankData &&
        bankData.deposit1 !== '0.00' &&
        bankData.deposit2 !== '0.00' &&
        bankData.attempts < 3 &&
        bankData.validated === false) {
        currentState = validationState.PENDING_VALIDATION
        validationAlert = <Alerts
            status='warning'
            alert={'PENDING_VALIDATION'}
        ></Alerts>
    }

    // El usuario ha validado el ingreso
    if (bankData &&
        bankData.deposit1 !== 0 &&
        bankData.deposit2 !== 0 &&
        bankData.attempts < 3 &&
        bankData.validated === true) {
        currentState = validationState.VALIDATED
        validationAlert = <Alerts
            status='200'
            alert={'VALIDATED_BANK_ACCOUNT'}
        ></Alerts>
    }

    // El usuario a superado el límite de intentos de validación
    if (bankData &&
        bankData.attempts >= 3 &&
        bankData.validated === false) {
        currentState = validationState.NOT_VALIDATED
        validationAlert = <Alerts
            status='300'
            alert={`NOT_VALIDATED`}
        ></Alerts>
    }

    // console.log('errors', bankDataErrors)

    return (
        <div className='mb-3'>

            <FinancialInformationForm
                bankDataErrors={bankDataErrors}
                validationAlert={validationAlert}
                banksOptions={banksOptions}
                hasFilledShippingAddress={hasFilledShippingAddress}
            />

            {
                currentState === validationState.PENDING_VALIDATION &&
                <BankDataValidationForm
                    bankData={bankData}
                />
            }
        </div>
    )
}

export default FinancialInformationContainer
