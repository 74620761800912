import { push } from 'connected-react-router';
import {
    getIsLoadingSalesItems,
    getAvailableLangs,
    getAvailableStatus,
    getAvailableLangsIncludingAny,
    getSaleItemsCurrentPage,
    getSaleItemsLastPage,
    getIsLoadingProduct
} from './../../store/products/reducers'
import { getIsAdding } from './../../store/cart/reducers';
import { getBankData, getIsBusiness, getIsLogged, getUserId, getVerified, getHasFilledShippingAddress } from './../../store/login/reducers'
import { connect } from 'react-redux'
import { byProductId, getProductSales, getProductBuys } from '../../store/products/reducers'
import { fetchProduct, fetchProductSales, fetchProductBuys } from '../../store/products/actions'
import { addToCart } from '../../store/cart/actions'


import Product from './Product'
import { getUserName } from '../../store/login/reducers';
import * as ui from '../../store/ui/actions'
import { showLoginModal } from '../../store/ui/actions'
import { deleteItemFromSale } from '../../store/sale/actions'
import { deleteItemFromBuy } from '../../store/buy/actions'

const mapStateToProps = (state, { match }) => {
    return {
        id: match.params.id,
        product: byProductId(state, match.params.id),
        saleItems: getProductSales(state, match.params.id),
        saleItemsCurrentPage: getSaleItemsCurrentPage(state),
        saleItemsLastPage: getSaleItemsLastPage(state),
        buyItems: getProductBuys(state, match.params.id),
        userName: getUserName(state),
        isLogged: getIsLogged(state),
        verified: getVerified(state),
        bankData: getBankData(state),
        addingToCart: getIsAdding(state),
        isLoadingProduct: getIsLoadingProduct(state),
        isLoading: getIsLoadingSalesItems(state),
        availableLangs: getAvailableLangs(state),
        availableLangsIncludingAny: getAvailableLangsIncludingAny(state),
        availableStatus: getAvailableStatus(state),
        userId: getUserId(state),
        hasFilledShippingAddress:getHasFilledShippingAddress(state),
        isBusiness: getIsBusiness(state),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProduct: (productId, locale) => {
            return dispatch(fetchProduct(productId, locale))
        },
        fetchProductSales: (productId) => {
            return dispatch(fetchProductSales(productId))
        },
        fetchProductBuys: (productId, locale) => {
            return dispatch(fetchProductBuys(productId, locale))
        },
        addToCart: (productId, qty, locale) => {
            return dispatch(addToCart(productId, qty, locale))
        },
        push: (url) => {
            dispatch(push(url))
        },
        onShowUpLogin: () => {
            dispatch(showLoginModal())
        },
        deleteItemFromSale: (itemId) => {
            return dispatch(deleteItemFromSale(itemId))
        },
        deleteItemFromBuy: (itemId) => {
            return dispatch(deleteItemFromBuy(itemId))
        },
    }
}

const ProductContainer = connect(mapStateToProps, mapDispatchToProps)(Product)

export default ProductContainer
