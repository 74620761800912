import React from 'react'
import Translation from '../global/Translation'

import { defineMessages } from 'react-intl'

defineMessages({
    LOGIN_REQUIRED: {
        id: 'LOGIN_REQUIRED',
        description: 'LOGIN_REQUIRED',
        defaultMessage: 'Login required',
    },
    NOT_ENOUGH_CREDIT: {
        id: 'NOT_ENOUGH_CREDIT',
        description: 'NOT_ENOUGH_CREDIT',
        defaultMessage: 'Not enough credit',
    },
    OTHER_ORDERS_PENDING_TO_PAY: {
        id: 'OTHER_ORDERS_PENDING_TO_PAY',
        description: 'OTHER_ORDERS_PENDING_TO_PAY',
        defaultMessage: 'You still have pending payments',
    },
    RESEND_EMAIL_EVENT: {
        id: 'RESEND_EMAIL_EVENT',
        description: 'RESEND_EMAIL_EVENT',
        defaultMessage: "If your email is valid.'We have emailed your password reset link!",
    },
    im_looking_for: {
        id: 'im_looking_for',
        description: 'im_looking_for',
        defaultMessage: 'I am looking for...',
    },
    REFERENCE_PRICE: {
        id: 'REFERENCE_PRICE',
        description: 'REFERENCE_PRICE',
        defaultMessage: 'Reference price {price} at {date}',
    },
})
const Alerts = ({ alert, status, values, hideIcon = false }) => {
    let cssClasses = 'alert mb-1 mt-1 candc-alert '
    let icon = ''
    if (status === 'warning') {
        cssClasses += 'alert-warning'
        icon = 'campaign'
    } else if (status === 'info') {
        cssClasses += 'alert-info'
        icon = 'campaign'
    } else if (status === 'primary') {
        cssClasses += 'alert-primary'
        icon = 'campaign'
    } else if (status >= 200 && status < 300) {
        cssClasses += 'alert-success'
        icon = 'check_circle'
    } else if (status >= 300) {
        cssClasses += 'alert-danger'
        icon = 'report'
    } else {
        cssClasses += 'alert-dark'
        icon = 'report'
    }
    if(hideIcon){
        icon = ''
    }
    const error =
        alert != null ? (
            <div className={cssClasses} role="alert" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
                <span className="material-icons-outlined" style={{ fontSize: '32px', borderRight: '2px solid black', marginRight: '8px', paddingRight: '8px' }}>
                    {icon}
                </span>
                <Translation id={alert} values={values ? values : {}} />
            </div>
        ) : (
            ''
        )

    return <div>{error}</div>
}
export default Alerts
