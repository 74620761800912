import { fetchCredit } from '../store/login/actions'
import { getBankData, getBuyCredit, getUserId, getUserName } from '../store/login/reducers'
import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Translation from '../components/global/Translation'
import { setBuyCredit } from '../store/login/actions'
import Alerts from '../components/alerts/Alerts'


const AddCreditByBankScreen = (props) => {
    const dispatch = useDispatch()
    const buyCredit = useSelector((state) => getBuyCredit(state))
    const userName = useSelector((state) => getUserName(state))
    const userId = useSelector((state) => getUserId(state))
    const bankData = useSelector((state) => getBankData(state))
    useEffect(() => { dispatch(fetchCredit())}, [])

    const onSubmit = (values, action) => {
        dispatch(setBuyCredit(values))
    }

    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 ">
                        <h2>
                            <Translation id="add_credit_by_wire_transfer" defaultMessage="Add credit by wire transfer" />
                        </h2>
                    </div>
                </div>

                <div className="row">
                    <div className="d-flex flex-column">
                        <p>
                            <Translation id="account_holder" defaultMessage="Account Holderr" />: Felix Dreams Privated Limited
                        </p>

                        <p>
                            Bank name: UOB (United Overseas Bank Limited)
                        </p>

                        <p>
                            <Translation id="Account_number" defaultMessage="Account number" />
                            : 420-326-419-9
                        </p>
                        <p>
                            <Translation id="transfer_reason" defaultMessage="Transfer Reason" />: {userName}-{userId}
                        </p>
                    </div>
                </div>
                <div className="row mt-3">
                    <Alerts
                        status="warning"
                        alert={`Attention: Always enter ${userName}-${userId} as transfer details! Please, do not enter any other information in this field.`}
                    ></Alerts>
                </div>
            </div>
        </React.Fragment>
    )
}
export default withRouter(injectIntl(connect()(AddCreditByBankScreen)))
