import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { defineMessages, injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Button from '../components/global/Button'
import businessDisable from '../assets/img/register/business-disabled.png'
import businessEnableable from '../assets/img/register/business-enabled.png'
import individualDisable from '../assets/img/register/individual-disabled.png'
import individualEnable from '../assets/img/register/individual-enabled.png'
import Translation from '../components/global/Translation'
import { setNestedObjectValues, useFormik } from 'formik'
import * as Yup from 'yup'
import {createAccount} from '../store/login/actions'
import * as ui from '../store/ui/actions'
import LocalizedLink from '../components/global/LocalizedLink'
import { getCreateAccountErrors } from '../store/login/reducers'

defineMessages({
    BETWEEN_3_AND_10_APHANUMERICS_CHARACTERS: {
        id:'BETWEEN_3_AND_10_APHANUMERICS_CHARACTERS',
        description:'BETWEEN_3_AND_10_APHANUMERICS_CHARACTERS',
        defaultMessage: "Between 3 and 10 alphanumerics characters"
    },
    A_VALID_AND_EXISTING_EMAIL:{
        id:'A_VALID_AND_EXISTING_EMAIL',
        description: 'A_VALID_AND_EXISTING_EMAIL',
        defaultMessage:"A valid and existing email"
    },
    YOUR_REGISTERED_EMAIL:{
        id:'YOUR_REGISTERED_EMAIL',
        description: 'YOUR_REGISTERED_EMAIL',
        defaultMessage:"Your registered email"
    },
    NUMBERS_AND_LETTERS:{
        id:'NUMBERS_AND_LETTERS',
        description:'NUMBERS_AND_LETTERS',
        defaultMessage:"Numbers and letters (optional)"
    },
    AT_LEAST_6_CHARACTERS: {
        id:'AT_LEAST_6_CHARACTERS',
        description:'AT_LEAST_6_CHARACTERS',
        defaultMessage:"At least 6 characters"
    },
    WRITE_THE_PASSWORD_AGAIN:{
        id:'WRITE_THE_PASSWORD_AGAIN',
        description:'WRITE_THE_PASSWORD_AGAIN',
        defaultMessage:"Write the password, again"
    },
    TERMS_OF_SERVICE:{
        id:'TERMS_OF_SERVICE',
        description:'TERMS_OF_SERVICE',
        defaultMessage:"Terms of service"
    },
    PRIVATE_POLICY:{
        id:'PRIVATE_POLICY',
        description:'PRIVATE_POLICY',
        defaultMessage:"Private policy"
    },
    REQUIRED:{
        id:'REQUIRED',
        description:'REQUIRED',
        defaultMessage:'Required'
    },
    MUST_BE_3_CHARACTERS_OR_MORE:{
        id:'MUST_BE_3_CHARACTERS_OR_MORE',
        description:'MUST_BE_3_CHARACTERS_OR_MORE',
        defaultMessage:'Must be 3 characters or more'
    },
    MUST_BE_10_CHARACTERS_OR_LESS:{
        id:'MUST_BE_10_CHARACTERS_OR_LESS',
        description:'MUST_BE_10_CHARACTERS_OR_LESS',
        defaultMessage:'Must be 10 characters of less'
    },
    INVALID_EMAIL_ADDRESS:{
        id:'INVALID_EMAIL_ADDRESS',
        description:'INVALID_EMAIL_ADDRESS',
        defaultMessage:'Invalid email address'
    },
    MUST_BE_6_CHARACTERS_OR_MORE: {
        id:'MUST_BE_6_CHARACTERS_OR_MORE',
        description:'MUST_BE_6_CHARACTERS_OR_MORE',
        defaultMessage:'Must be 6 characters or more'
    },
    PASSWORDS_MUST_MATCH: {
        id:'PASSWORDS_MUST_MATCH',
        description:'PASSWORDS_MUST_MATCH',
        defaultMessage:'Passwords must match'
    },
    PLEASE_ACCEPT_THE_TERMS_OF_SERVICE: {
        id:'PLEASE_ACCEPT_THE_TERMS_OF_SERVICE',
        description:'PLEASE_ACCEPT_THE_TERMS_OF_SERVICE',
        defaultMessage:'Please, accept the terms of service'
    },
    NAME_ALREADY_TAKEN:{
        id:'NAME_ALREADY_TAKEN',
        description:'NAME_ALREADY_TAKEN',
        defaultMessage:'The name has already been taken.'
    },
    INVALID_DATA: {
        id:'INVALID_DATA',
        description:'INVALID_DATA',
        defaultMessage:'The given data were invalid.'
    }
})

const RegisterScreen = (props) => {

    const dispatch = useDispatch()

    const onShowUpLogin = (evt) => {
        evt.preventDefault()
        dispatch(ui.showLoginModal())
    }

    const handleIsBussinessChange = function(isBusiness) {
        formik.setValues({...formik.values,'isBusiness':isBusiness})
        if(!isBusiness){
            formik.setFieldTouched('vatPrefix',false)
            formik.setFieldTouched('vatNumber',false)
            formik.setValues({...formik.values,'isBusiness':isBusiness,'vatPrefix':'','vatNumber':''})
        }
    }

    const error = useSelector(state => state.login.alert)
    const errorsCreateAccount = useSelector(state => getCreateAccountErrors(state))

    useEffect(() => {
        if(errorsCreateAccount){
            formik.setTouched(setNestedObjectValues(errorsCreateAccount, true), false).then(() => {
                formik.setErrors(errorsCreateAccount)
            })
        }
    },[errorsCreateAccount])

    const initialValues = {
        name: '',
        email: '',
        vatPrefix:'',
        vatNumber:'',
        password:'',
        password_confirmation:'',
        isTOSAcepted:false, 
        isBusiness:false
    }

    const validationSchema = Yup.object({
        name: Yup.string()
            .required(props.intl.formatMessage({id:'REQUIRED'}))
            .min(3,props.intl.formatMessage({id:'MUST_BE_3_CHARACTERS_OR_MORE'}))
            .max(15,props.intl.formatMessage({id:'MUST_BE_10_CHARACTERS_OR_LESS'})),
        email: Yup.string()
            .email(props.intl.formatMessage({id:'INVALID_EMAIL_ADDRESS'}))
            .required(props.intl.formatMessage({id:'REQUIRED'})),
        vatPrefix: Yup.string().when('isBusiness',{
            is:true, 
            then: Yup.string().required(props.intl.formatMessage({id:'REQUIRED'}))
        }),
        vatNumber: Yup.string().when('isBusiness',{
            is:true, 
            then: Yup.string().required(props.intl.formatMessage({id:'REQUIRED'}))
        }),
        password: Yup.string()
            .required(props.intl.formatMessage({id:'REQUIRED'}))
            .min(6, props.intl.formatMessage({id:'MUST_BE_6_CHARACTERS_OR_MORE'})),
        password_confirmation: Yup.string().oneOf([
            Yup.ref('password'),''],props.intl.formatMessage({id:'PASSWORDS_MUST_MATCH'}))
            .required(props.intl.formatMessage({id:'REQUIRED'})),
        isTOSAcepted: Yup.boolean().oneOf([true],props.intl.formatMessage({id:'PLEASE_ACCEPT_THE_TERMS_OF_SERVICE'}))
    })

    const onSubmit = values => {
        dispatch(createAccount(values, props.intl.formatMessage({id:'ROUTE_THANKS_YOU_FOR_REGISTER_PAGE'})))
    }


    const formik = useFormik({
        initialValues ,
        onSubmit,
        validationSchema
    })
    
    const handleClickSubmit =  event => {
        formik.submitForm()
    }
    
    return (
        <React.Fragment>
            <div className="container-fluid registerForm mb-5" id="registerForm">
                <div className="row">
                    <div className="col-12 col-md-2">
                        <div className="row">
                            <div className="col-6 col-md-12 text-center mt-md-5" onClick={() => handleIsBussinessChange(false)} style={{ cursor: 'pointer' }}>
                                <img className="registerImg" src={formik.values.isBusiness ? individualDisable : individualEnable} alt="businessImage" />
                                <div>
                                    <Translation id="NORMAL_USER" defaultMessage="Usuario normal" />
                                </div>
                            </div>
                            <div className="col-6 col-md-12 text-center mt-md-5" onClick={() => handleIsBussinessChange(true)} style={{ cursor: 'pointer' }}>
                                <img className="registerImg" src={formik.values.isBusiness ? businessEnableable : businessDisable} alt="businessImage" />
                                <div>
                                    <Translation id="BUSINESS_USER" defaultMessage="Business_user" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-10">
                        <h2>
                            <Translation id="REGISTER_FORM" defaultMessage="Register form" />
                        </h2>
                        <div className="">
                            <div className="q-top">
                                {formik.values.isBusiness ? (
                                    <Translation id="BUSINESS_USER" defaultMessage="Business_user" />
                                ) : (
                                    <Translation id="NORMAL_USER" defaultMessage="Usuario normal" />
                                )}
                            </div>
                            <form className="form" onSubmit={formik.handleSubmit}>
                                <div className="row my-3">
                                    <div className="col-12 col-md-3 lab ">
                                        <label htmlFor="registration_name" className="form-label">
                                            <Translation id="USER_NAME" defaultMessage="User name" />
                                        </label>
                                        *
                                    </div>
                                    <div className="col-12 col-md-6 name validable">
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="name"
                                            name="name"
                                            required="required"
                                            placeholder={props.intl.formatMessage({ id: 'BETWEEN_3_AND_10_APHANUMERICS_CHARACTERS' })}
                                            pattern="[A-Za-z0-9ñÑáéíóúÁÉÍÓÚüÜ]{3,10}"
                                            maxLength="10"
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            autoComplete="off"
                                        />
                                        {formik.touched.name && formik.errors.name ? <div style={{ color: 'red' }}>{formik.errors.name}</div> : null}
                                    </div>
                                    <div className="col-md-3 d-none d-md-block " >
                                        <Translation id="ALREADY_REGISTERED" defaultMessage="Already registered? Login" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12 col-md-3 ">
                                        <label htmlFor="registration_email" className="form-label">
                                            <Translation id="EMAIL" defaultMessage="Email" />
                                        </label>
                                        *
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <input
                                            type="email"
                                            id="registration_email"
                                            name="email"
                                            required="required"
                                            placeholder={props.intl.formatMessage({ id: 'A_VALID_AND_EXISTING_EMAIL' })}
                                            className="form-control"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            autoComplete="off"
                                        />
                                        {formik.touched.email && formik.errors.email ? <div style={{ color: 'red' }}>{formik.errors.email}</div> : null}
                                    </div>
                                    <div className="col-md-3 d-none d-md-block ">
                                        <button className="bt noselect bt-size-small" onClick={onShowUpLogin} tabIndex='-1'>
                                            <Translation id="CLICK_TO_LOGIN" defaultMessage="Click to Login" />
                                        </button>
                                    </div>
                                </div>
                                {formik.values.isBusiness && (
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-3 ">
                                            <label htmlFor="registration_vatNumber" className="form-label">
                                                <Translation id="VAT_NUMBER" defaultMessage="Vat number" />
                                            </label>
                                            *
                                        </div>
                                        <div className="col-2">
                                            <select
                                                className="form-select"
                                                id="registration_vatPrefix"
                                                name="vatPrefix"
                                                value={formik.values.vatPrefix}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            >
                                                <option value="">*</option>
                                                <option value="AT">AT</option>
                                                <option value="BE">BE</option>
                                                <option value="BG">BG</option>
                                                <option value="HR">HR</option>
                                                <option value="CZ">CZ</option>
                                                <option value="EE">EE</option>
                                                <option value="DE">DE</option>
                                                <option value="EL">EL</option>
                                                <option value="IN">IN</option>
                                                <option value="LT">LT</option>
                                                <option value="ES">ES</option>
                                                <option value="SE">SE</option>
                                                <option value="GB">GB</option>
                                                <option value="CY">CY</option>
                                                <option value="HU">HU</option>
                                                <option value="LU">LU</option>
                                                <option value="RO">RO</option>
                                                <option value="SK">SK</option>
                                                <option value="DK">DK</option>
                                                <option value="FI">FI</option>
                                                <option value="LV">LV</option>
                                                <option value="FR">FR</option>
                                                <option value="SI">SI</option>
                                                <option value="IT">IT</option>
                                                <option value="NL">NL</option>
                                                <option value="IE">IE</option>
                                                <option value="PL">PL</option>
                                                <option value="PT">PT</option>
                                                <option value="MT">MT</option>
                                            </select>
                                            {formik.touched.vatPrefix && formik.errors.vatPrefix ? <div style={{ color: 'red' }}>{formik.errors.vatPrefix}</div> : null}
                                        </div>
                                        <div className="col-4">
                                            <input
                                                className="form-control"
                                                type="text"
                                                id="registration_vatNumber"
                                                name="vatNumber"
                                                placeholder={props.intl.formatMessage({ id: 'NUMBERS_AND_LETTERS' })}
                                                pattern="[A-Za-z0-9]*"
                                                value={formik.values.vatNumber}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.vatNumber && formik.errors.vatNumber ? <div style={{ color: 'red' }}>{formik.errors.vatNumber}</div> : null}
                                        </div>
                                    </div>
                                )}
                                <div className="row mb-3">
                                    <div className="col-12 col-md-3 ">
                                        <label htmlFor="registration_password" className="form-label">
                                            <Translation id="NEW_PASSWORD" defaultMessage="Nueva contraseña" />
                                        </label>
                                        *
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <input
                                            className="form-control"
                                            type="password"
                                            id="registration_password"
                                            name="password"
                                            required="required"
                                            placeholder={props.intl.formatMessage({ id: 'AT_LEAST_6_CHARACTERS' })}
                                            pattern=".{6,}"
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            autoComplete="off"
                                        />
                                        {formik.touched.password && formik.errors.password ? <div style={{ color: 'red' }}>{formik.errors.password}</div> : null}
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12 col-md-3 ">
                                        <label htmlFor="registration_plainPassword" className="form-label">
                                            <Translation id="CONFIRM_PASSWORD" defaultMessage="Confirm password" />
                                        </label>
                                        *
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <input
                                            type="password"
                                            id="registration_plainPassword"
                                            name="password_confirmation"
                                            required="required"
                                            placeholder={props.intl.formatMessage({ id: 'WRITE_THE_PASSWORD_AGAIN' })}
                                            pattern=".{6,}"
                                            className="form-control"
                                            value={formik.values.password_confirmation}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            autoComplete="off"
                                        />
                                        {formik.touched.password_confirmation && formik.errors.password_confirmation ? (
                                            <div style={{ color: 'red' }}>{formik.errors.password_confirmation}</div>
                                        ) : null}
                                        {error && <div style={{ color: 'red' }}>{props.intl.formatMessage({ id: 'INVALID_DATA' })}</div>}
                                    </div>
                                </div>
                                <div className="row mb-3 justify-content-md-center">
                                    <div className="col-12 col-md-4  ">
                                        <div className="form-check">
                                            <label htmlFor="isTOSAcepted" className="form-check-label">
                                                I agree to the{' '}
                                                <LocalizedLink routeId="ROUTE_STATIC_PAGE" className="colored-link" target="_blank" params={{ view: 'terms-of-service' }}>
                                                    <Translation id="TERMS_OF_SERVICE" />
                                                </LocalizedLink>{' '}
                                                and{' '}
                                                <LocalizedLink routeId="ROUTE_STATIC_PAGE" className="colored-link" target="_blank" params={{ view: 'private-policy' }}>
                                                    <Translation id="PRIVATE_POLICY" definedMessage="Private Policy" />
                                                </LocalizedLink>{' '}
                                                of Cards and Collections
                                            </label>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="isTOSAcepted"
                                                name="isTOSAcepted"
                                                value={formik.values.isTOSAcepted}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.isTOSAcepted && formik.errors.isTOSAcepted ? <div style={{ color: 'red' }}>{formik.errors.isTOSAcepted}</div> : null}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <Button
                                            type="submit"
                                            disabled={!formik.dirty || !formik.isValid}
                                            className="btn btn-primary register_button"
                                            value="Register"
                                            onClick={handleClickSubmit}
                                        >
                                            <Translation id="REGISTER" defaultMessage="Register" />
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default withRouter(injectIntl(connect()(RegisterScreen)))
