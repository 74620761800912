import React from 'react'
import Translation from '../../global/Translation'
import LocalizedLink from '../../global/LocalizedLink'
import { NotificationCard } from './NotificationCard'
import { OrderLink } from '../../tasks/OrderLink'
import moment from 'moment'

export const ChatPendingMiniNotification = (({ notification, translationId, translationParams = {} }) => {
    return <NotificationCard
        urlLinkComponentConfig={{
            component: LocalizedLink,
            props: {
                routeId: 'ROUTE_USER_DASHBOARD',
                params: { view: 'chats', id: notification.chat_id },
            }
        }}
        iconName='message'
    >
        <div>
            <Translation id={translationId} values={{ ...translationParams }} />
        </div>
    </NotificationCard>
})

export const OrderMiniNotification = ({ notification, translationId, translationParams = {} }) => {
    return <NotificationCard
        urlLinkComponentConfig={{
            component: LocalizedLink,
            props: {
                routeId: 'ROUTE_ORDER',
                params: { id: notification.order_id },
            }
        }}
        iconName='sell'
    >
        <div>
            <div>
                <Translation id={translationId} values={{ ...translationParams }} />
            </div>
            <div>
                <OrderLink orderId={notification.order_id} className='colored-link'></OrderLink> {moment(notification.created_at).format('DD/MM/YYYY').toString()}
            </div>
        </div>
    </NotificationCard>
}

export const PendingTaskMiniNotification = ({ translationId, qtyPendingTask }) => {
    return <NotificationCard
        urlLinkComponentConfig={{
            component: LocalizedLink,
            props: {
                routeId: 'ROUTE_USER_DASHBOARD',
                params: { view: '', id: '' },
            }
        }}
        iconName='pending_actions'
    >
        <div>
            <span className='badge badge-pill badge-secondary'>{qtyPendingTask}</span> <Translation
            id={translationId} />
        </div>
    </NotificationCard>
}
