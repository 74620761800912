import { createSelector } from 'reselect'
import { LOCATION_CHANGE } from 'connected-react-router'

export const initState = {
    notificationsList: [],
    showModal: false,
}

const notifications = (state = initState, action) => {
    switch (action.type) {
        case LOCATION_CHANGE:
            return Object.assign({}, state, { showModal: false })
        case 'RESET_UI':
            return Object.assign({}, state, { showModal: false })
        case 'FETCH_MY_NOTIFICATIONS_SUCCESS':
            return { ...state, notificationsList: [...action.notifications] }
        case 'SHOW_NOTIFICATIONS_MODAL':
            return Object.assign({}, state, { showModal: true })
        case 'CLOSE_NOTIFICATIONS_MODAL':
            return Object.assign({}, state, { showModal: false })
        case 'NOTIFICATION_CREATED_RECEIVED':
            return { ...state, notificationsList: [...state.notificationsList, action.notification] }
        case 'NOTIFICATION_DELETED':
            return { ...state, notificationsList: [...state.notificationsList].filter(notification => notification.id !== action.notificationDeletedId) }
        case 'LOGOUT':
            return initState
        default:
            return state
    }
}

export const getNotifications = (state) => {
    return state.notifications.notificationsList;
}

export const qtyNotificationsSelector = createSelector(getNotifications, notifications => notifications.length)

export const showModalSelector = (state) => {
    return state.notifications.showModal
}

export default notifications
