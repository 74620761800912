import React from 'react'
import { OrderLink } from './OrderLink'
import Translation from '../global/Translation'
import LocalizedLink from '../global/LocalizedLink'
import { CheckoutLink } from './CheckoutLink'
import moment from 'moment/moment'
import { NotificationCard } from '../notifications/notification/NotificationCard'

const Task = ({ task }) => {
    const taskUserId = task.user_id
    let otherUserType = 'Seller'
    let otherUserName = task.seller_name

    if (taskUserId === task.seller_id) {
        otherUserType = 'Buyer'
        otherUserName = task.buyer_name
    }


    if (task.checkout_id) {
        return <NotificationCard
            urlLinkComponentConfig={{
                component: LocalizedLink,
                props: {
                    routeId: 'ROUTE_ORDER_FINISHED',
                    params: { id: task.checkout_id },
                }
            }}
            iconName={'pending_actions'}
        >
            <div>
                <div>
                    <CheckoutLink checkoutId={task.checkout_id}
                                  className='colored-link'></CheckoutLink> {moment(task.created_at)
                    .format('DD MMM HH:mm')
                    .toString()}
                </div>
            </div>
        </NotificationCard>
    }

    if (!task.order_id) {
        return <NotificationCard
            urlLinkComponentConfig={{
                component: LocalizedLink,
                props: {
                    routeId: 'ROUTE_USER_PROFILE',
                    params: { view: 'financial-information' },
                }
            }}
            iconName={'pending_actions'}
        >
            <div>
                <Translation id='financial_information' defaultMessage='Financial information' />
            </div>
        </NotificationCard>

    }

    return <NotificationCard
        urlLinkComponentConfig={{
            component: LocalizedLink,
            props: {
                routeId: 'ROUTE_ORDER',
                params: { id: task.order_id },
            }
        }}
        iconName={'pending_actions'}
    >
        <div>
            <div>
                <OrderLink orderId={task.order_id}
                           className='colored-link'></OrderLink> ({otherUserType}: {otherUserName})
            </div>
        </div>
    </NotificationCard>
}


export const TasksInfo = ({ tasks, title }) => {

    return (
        <div>
            <strong>{title}</strong>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', padding: '8px 0' }}>
                {
                    tasks.map((task) => <Task key={task.id} task={task}></Task>)
                }
            </div>
        </div>
    )
}
