import { LOCATION_CHANGE } from 'connected-react-router'
import jwtDecode from 'jwt-decode'
// initial state
export const initState = {
    isLogged: false,
    verified: false,
    username: null,
    userNameSlug: null,
    userId: null,
    token: null,
    showModal: false,
    alert: null,
    status: null,
    impersonatedBy: null,
    userType: null,
    salesOrg: null,
    specialNames: [],
    userPriceList: null,
    //  discounts: [],
    priceList: null,
    shippingAddress: null,
    hasFilledShippingAddress: false,
    bankData: null,
    seenOutletModal: false,
    showPricesDisclaimer: true,
    canViewNewOrders: false,
    mandatoryChangePasswordOldPassword: null,
    mandatoryChangePassword: false,
    userAuthorized: null,
    credit: null,
    addCreditErrors: null,
    comission: null,
    isBusiness: false,
    vatPrefix: null,
    vatNumber: null,
    errorsBuyCredit: null,
    errorsBankData: null,
    errorsCreateAccount: null,
    banksOptions: null,
    sendNotifications: null,
    businessConfig: {
        allowPickup: false,
        allowPickupBetweenUsersInMyStore: false,
    },
    dropOffOptions: {
        allowInStoreDropOffShippingMethod: false,
        dropOffStores: [],
    },
    loyaltiesOfUser: [],
    loading: false,
    isAdmin: false,
}

const assignUserData = (userData) => {
    return {
        userId: userData.userId,
        username: userData.username,
        userNameSlug: userData.userNameSlug,
        isAdmin: userData.isAdmin,
        email: userData.email,
        verified: userData.verified,
        userType: userData.type,
        buyCredit: userData.buyCredit,
        credit: userData.credit,
        comission: userData.comission,
        vatNumber: userData.vatNumber,
        vatPrefix: userData.vatPrefix,
        isBusiness: userData.isBusiness,
        shippingAddress: userData.shippingAddress,
        hasFilledShippingAddress: !!userData.shippingAddress,
        bankData: userData.bankData,
        votes: userData.votes,
        sendNotifications: userData.sendNotifications,
        loyaltiesOfUser: userData.loyaltiesOfUser,
        businessConfig: {
            allowPickup: userData.allowPickup ?? false,
            allowPickupBetweenUsersInMyStore: userData.allowPickupBetweenUsersInMyStore ?? false,
        },
        dropOffOptions: {
            allowInStoreDropOffShippingMethod: userData.allowInStoreDropOffShippingMethod ?? false,
            dropOffStores: userData.dropOffStores ?? [],
        },
    }
}

// reducer
const login = (state = initState, action) => {
    switch (action.type) {
        case LOCATION_CHANGE:
            return Object.assign({}, state, {
                alert: null, status: null, addCreditErrors: null,
                errorsBuyCredit: null,
                errorsBankData: null,
                errorsCreateAccount: null
            })
        case 'RESET_MESSAGES':
            return { ...state, errorsBuyCredit: null }
        case 'SHOW_LOGIN_MODAL':
            return Object.assign({}, state, { showModal: true })
        case 'CLOSE_LOGIN_MODAL':
            return Object.assign({}, state, {
                showModal: false,
                alert: null,
                status: null,
                mandatoryChangePassword: false
            })
        case 'LOGIN_REQUIRED':
            return Object.assign({}, state, {
                showModal: true,
                callback: action.callback,
                alert: `${action.payload.statusText}`
            })
        case 'GET_CREDIT_SUCCESS':
            return Object.assign({}, state, {
                buyCredit: action.buyCreditAfter,
                credit: action.creditAfter,
                verified: action.verified
            })
        case 'USER_REFRESH_JWT_DATA_SUCCESS':
            return {
                ...state,
                ...assignUserData(action.userJwtData)
            };
        case 'LOGIN_SUCCESS':
            const decoded = jwtDecode(action.token)
            return Object.assign({}, state, {
                ...assignUserData(decoded),
                token: action.token,
                isLogged: true,
                showModal: false,
                alert: null,
            })
        case 'LOGIN_FAILURE':
            return Object.assign({}, state, { alert: action.statusText })
        case 'CREATE_ACCOUNT_SUCCESS':
            return Object.assign({}, state, {
                alert: action.statusText,
                status: action.status,
                username: action.name,
                errorsCreateAccount: null
            })
        case 'CREATE_ACCOUNT_FAILURE':
            return Object.assign({}, state, {
                alert: action.statusText,
                status: action.status,
                errorsCreateAccount: action.errors
            })
        case 'RECOVER_PASSWORD_SUCCESS':
            return Object.assign({}, state, { alert: action.statusText, status: action.status })
        case 'RECOVER_PASSWORD_FAILURE':
            return Object.assign({}, state, { alert: action.statusText, status: action.status })
        case 'RESET_PASSWORD_SUCCESS':
            return Object.assign({}, state, { alert: action.statusText, status: action.status })
        case 'RESET_PASSWORD_FAILURE':
            return Object.assign({}, state, { alert: action.statusText, status: action.status })
        case 'RESET_UI':
            return Object.assign({}, state, { showModal: false, showSSOModal: false })
        case 'UPDATE_PERSONAL_INFORMATION_SUCCESS':
            return Object.assign({}, state, {
                email: action.formData.email,
                vatNumber: action.formData.vatNumber,
                vatPrefix: action.formData.vatPrefix,
                sendNotifications: action.formData.sendNotifications,
                alert: null,
                status: 200
            })
        case 'UPDATE_PERSONAL_INFORMATION_FAILURE':
            return Object.assign({}, state, { alert: action.statusText, status: action.status })
        case 'UPDATE_BUSINESS_CONFIG_SUCCESS':
            return Object.assign({}, state, {
                businessConfig: {
                    allowPickup: action.formData.allowPickup,
                    allowPickupBetweenUsersInMyStore: action.formData.allowPickupBetweenUsersInMyStore,
                },
                alert: null,
                status: 200
            })
        case 'UPDATE_BUSINESS_CONFIG_FAILURE':
            return Object.assign({}, state, { alert: action.statusText, status: action.status })
        case 'UPDATE_USER_DROP_OFF_OPTIONS_SUCCESS':
            return Object.assign({}, state, {
                dropOffOptions: {
                    allowInStoreDropOffShippingMethod: action.allowInStoreDropOffShippingMethod,
                    dropOffStores: action.dropOffStores,
                },
                alert: null,
                status: 200
            })
        case 'UPDATE_USER_DROP_OFF_OPTIONS_FAILURE':
            return Object.assign({}, state, { alert: action.statusText, status: action.status })
        case 'CHANGE_PASSWORD_SUCCESS':
            return Object.assign({}, state, { alert: null, status: 200 })
        case 'CHANGE_PASSWORD_FAILURE':
            return Object.assign({}, state, { alert: action.statusText, status: action.status })
        case 'UPDATE_SHIPPING_INFORMATION_SUCCESS':
            return Object.assign({}, state, {
                shippingAddress: action.shippingAddress,
                alert: null,
                status: 200,
                hasFilledShippingAddress: true
            })

        case 'UPDATE_SHIPPING_INFORMATION_FAILURE':
            return Object.assign({}, state, {
                alert: action.statusText,
                status: action.status,
                errorsShippingAddress: action.errors
            })
        case 'UPDATE_FINANCIAL_INFORMATION_SUCCESS':
            const bankData = {
                bank_id: action.formData.bank_id,
                account_holder: action.formData.account_holder,
                account_number: action.formData.account_number,
                bic_swift: action.formData.bic_swift
            }

            return Object.assign({}, state, {
                bankData,
                alert: null,
                status: 200,
                errorsBankData: null
            })
        case 'UPDATE_FINANCIAL_INFORMATION_FAILURE':
            return Object.assign({}, state, {
                alert: action.statusText,
                status: action.status,
                errorsBankData: action.errors
            })
        case 'RETRIEVE_CREDIT_SUCCESS':
            return { ...state, credit: action.creditAfter }
        case 'SET_BUY_CREDIT_SUCCESS':
            return Object.assign({}, state, { buyCredit: action.buyCredit, errorsBuyCredit: null })
        case 'SET_BUY_CREDIT_FAILURE':
            return { ...state, errorsBuyCredit: action.errors }
        case 'ADD_CREDIT_FAILURE':
            return { ...state, addCreditErrors: action.errors }
        case 'USER_CREDIT_CHANGED':
            return { ...state, credit: action.afterCredit, buyCredit: action.buyCredit }
        case 'GET_BANK_VALIDATION_INFO_SUCCESS':
            return {
                ...state,
                bankData: {
                    ...state.bankData,
                    deposit1: action.bankDataValidationInfo.deposit1,
                    deposit2: action.bankDataValidationInfo.deposit2,
                    attempts: action.bankDataValidationInfo.attempts,
                    validated: action.bankDataValidationInfo.validated
                }
            }
        case 'GET_BANK_VALIDATION_INFO_FAILURE':
            return Object.assign({}, state, {
                alert: action.statusText,
                status: action.status
            })
        case 'UPDATE_BANK_VALIDATION_SUCCESS':
        case 'UPDATE_BANK_VALIDATION_FAILURE':
            return {
                ...state,
                bankData: {
                    ...state.bankData,
                    attempts: ++state.bankData.attempts
                }
            }
        case 'SHIPPING_ADDRESS_NOT_FOUND':
            return { ...state, hasFilledShippingAddress: false }
        case 'GET_BANKS_OPTIONS_SUCCESS':
            return { ...state, banksOptions: action.banksOptions }
        case 'LOGOUT':
            return initState
        case 'VERIFY_ACCOUNT_REQUEST':
            return { ...state,  }
        default:
            return state
    }
}

// selectors
export const getToken = (state) => {
    return state.login.token
}
export const showModalSelector = (state) => {
    return state.login.showModal
}

export const getCreateAccountErrors = (state) => {
    return state.login.errorsCreateAccount
}
export const getIsLogging = (state) => {
    return state.ui.isLogging
}
export const getIsLogged = (state) => {
    return state.login.isLogged
}
export const getIsAdmin = (state) => {
    return state.login.isAdmin
}
export const getUserType = (state) => {
    return state.login.userType
}

export const getVerified = (state) => {
    return state.login.verified
}

export const getAlert = (state) => {
    return state.login.alert
}
export const getUserName = (state) => {
    return state.login.username
}

export const getUserNameSlug = (state) => {
    return state.login.userNameSlug
}

export const getIsBusiness = (state) => {
    return state.login.isBusiness
}

export const getVatPrefix = (state) => {
    return state.login.vatPrefix
}

export const getVatNumber = (state) => {
    return state.login.vatNumber
}
export const getCountOfVotes = (state) => {
    return state.login.votes
}
export const getUserId = (state) => {
    return state.login.userId
}
export const getName = (state) => {
    return state.login.name
}
export const getEmail = (state) => {
    return state.login.email
}
export const getCredit = (state) => {
    return state.login.credit
}
export const getAddCreditErrors = (state) => {
    return state.login.addCreditErrors
}
export const getBuyCredit = (state) => {
    return state.login.buyCredit
}

export const getBuyCreditErrors = (state) => {
    return state.login.errorsBuyCredit
}

export const getLoginCallback = (state) => {
    return state.login.callback
}

export const getShippingAddress = (state) => {
    return state.login.shippingAddress
}

export const getBanksOptionsSelector = (state) => {
    return state.login.banksOptions
}

export const getBankData = (state) => {
    return state.login.bankData
}

export const getBankDataErrors = (state) => {
    return state.login.errorsBankData
}

export const getShippingAddressErrors = (state) => {
    return state.login.errorsShippingAddress
}

export const getHasFilledShippingAddress = (state) => {
    return state.login.hasFilledShippingAddress
}

export const getSendNotifications = (state) => {
    return state.login.sendNotifications
}

export const getBusinessConfig = (state) => {
    return state.login.businessConfig;
}

export const getDropOffOptions = (state) => {
    return state.login.dropOffOptions;
}

export const getLoyaltiesOfUser = (state) => {
    return state.login.loyaltiesOfUser
}

export const getUserProfileLogoUrl = (state, userNameToSearch = null) => {
    const logoConfig = {
        'AgoraHobby': 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtPgU7SDhgkLYzdVLGqnufpVAfXMAFsocbV7i8ibQvRg&s',
        'Utopia SG': '/companyImages/Utopia_Games_Logo.png',
        'UtopiaGame': '/companyImages/Utopia_Games_Logo.png',
        'GOG': '/companyImages/GOG_Logo.png',
    }
    const userName = userNameToSearch ?? getUserName(state);
    return logoConfig[userName] ?? null;
}

export const getIsLoadingLogin = (state) => {
    return state.login.loading;
}

export default login
