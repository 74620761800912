import { fetchSaleItemById, fetchSaleItemByIdPublic } from './../store/sale/actions';
import {
    getSaleItems,
    getSaleItemsExpansionsAvailableList,
    getSaleItemsLastFilterUsed,
    getSaleItemsLastSellerId,
    getSaleItemsRarityAvailableList,
    getSaleItemsTableInfo,
    productsWithoutPrice
} from '../store/sale/reducers'
import { getCurrentUrl } from '../store/ui/reducers'
import {
    getAvailableLangsIncludingAny,
    getAvailableProductsCategoriesIncludingAny,
    getAvailableSaleItemsOrderBy,
    getAvailableStatusIncludingAny,
    getBooleanOptions,
    getCurrentCollectableContext
} from '../store/products/reducers'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { defineMessages, injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Translation from '../components/global/Translation'
import {
    deleteItemFromSale,
    fetchSaleItems,
    fetchSaleItemsFromUser,
    updatePriceFromPriceProvider,
    updateQtyItemFromSale
} from '../store/sale/actions'
import Button from '../components/global/Button'
import confirm from '../components/global/confirm'
import LocalizedLink from '../components/global/LocalizedLink'
import { getBankData, getIsBusiness, getIsLogged, getUserId, getUserName, getVerified } from '../store/login/reducers'
import { addToCart } from '../store/cart/actions'
import { getUserPublicInfo } from '../store/user/reducers'
import { fetchUserPublicInfoById } from '../store/user/actions'
import { getIsAdding } from '../store/cart/reducers'
import Alerts from '../components/alerts/Alerts'
import { RenderSaleItemMobile } from '../components/sale/list/RenderSaleItemMobile'
import { showLoginModal } from '../store/ui/actions'
import Modal from '../components/global/Modal'
import InfiniteScroll from 'react-infinite-scroll-component'
import debounce from 'lodash/debounce'
import { getDefaultProductCategoryByCurrentContext } from '../store/products/CollectableContext'
import SaleItemsFilters from '../components/sale/items/SaleItemsFilters'
import isEqual from 'react-fast-compare'
import { parseFiltersToLaravel } from '../components/shared/filters/filters-utils'
import SimplifiedResult from '../components/product/SimplifiedResult'
import SaleItemSimplifiedResult from '../components/product/SaleItemSimplifiedResult'

defineMessages({
    SEARCH_BY_NAME_OR_WHATEVER: {
        id: 'SEARCH_BY_NAME_OR_WHATEVER',
        description: 'SEARCH_BY_NAME_OR_WHATEVER',
        defaultMessage: 'Search by name or whatever'
    }
})

const mapStateToProps = (state) => {
    return {
        userIdLogged: getUserId(state),
        userName: getUserName(state),
        isLogged: getIsLogged(state),
        verified: getVerified(state),
        saleItems: getSaleItems(state),
        saleTableInfo: getSaleItemsTableInfo(state),
        productsCategoriesOptions: getAvailableProductsCategoriesIncludingAny(state),
        availableLangs: getAvailableLangsIncludingAny(state),
        availableStatus: getAvailableStatusIncludingAny(state),
        booleanOptions: getBooleanOptions(state),
        expansionOptions: getSaleItemsExpansionsAvailableList(state),
        rarityOptions: getSaleItemsRarityAvailableList(state),
        userListInfoDistinctCurrentUser: getUserPublicInfo(state),
        addingToCart: getIsAdding(state),
        bankData: getBankData(state),
        isBusiness: getIsBusiness(state),
        collectableContext: getCurrentCollectableContext(state),
        productsWithoutPrice: productsWithoutPrice(state),
        currentUrl: getCurrentUrl(state),
        availableOrderByOptions: getAvailableSaleItemsOrderBy(state),
        lastSellerId: getSaleItemsLastSellerId(state),
        lastFiltersUsed: getSaleItemsLastFilterUsed(state)
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    const userToFilter = ownProps.match.params.id
    return {
        fetchDistinctUserPublicInfo: () => {
            return dispatch(fetchUserPublicInfoById(userToFilter))
        },
        fetchSaleItems: (newPage, pageSize, filters, orderBy) => {
            if (userToFilter) {
                return dispatch(fetchSaleItemsFromUser(userToFilter, newPage, pageSize, filters, orderBy))
            } else {
                return dispatch(fetchSaleItems(newPage, pageSize, filters, orderBy))
            }
        },
        deleteItemFromSale: (itemId) => {
            return dispatch(deleteItemFromSale(itemId))
        },
        updateQtyItemFromSale: (itemId, qty) => {
            return dispatch(updateQtyItemFromSale(itemId, qty))
        },
        addToCart: (productId, qty, locale) => {
            return dispatch(addToCart(productId, qty, locale))
        },
        onShowUpLogin: () => {
            dispatch(showLoginModal())
        },
        updatePriceFromPriceProvider: (provider, operation, value, selectedMaterials) => {
            return dispatch(updatePriceFromPriceProvider(provider, operation, value, selectedMaterials))
        },
        fetchSaleItemByIdPublic: (itemId) => {
            return dispatch(fetchSaleItemByIdPublic(itemId))
        },
    }
}
const confirmDeleteItem = (itemId, item, onDeleteItem, intl) => {
    return confirm(intl.formatMessage({ id: 'CONFIRM_DELETE_ITEM_FROM_SALE' })).then(
        (confirm) => {
            return onDeleteItem(itemId)
        },
        (cancel) => {
            return Promise.reject()
        }
    )
}
const getDefaultFilters = () => {
    return {
        search: '',
        productCategory: getDefaultProductCategoryByCurrentContext(),
        selectedLang: {
            value: null,
            label: 'Any'
        },
        selectedExpansion: {
            value: null,
            label: 'Any'
        },
        selectedRarity: {
            value: null,
            label: 'Any'
        },
        selectedCondition: null,
        priceMin: 0.0,
        priceMax: 0.0,
        isFoil: {
            value: null,
            label: 'Any'
        },
        isSigned: {
            value: null,
            label: 'Any'
        },
        isPlayset: {
            value: null,
            label: 'Any'
        },
        isAltered: {
            value: null,
            label: 'Any'
        }
    }
}

const ProviderPriceModal = ({ productsWithoutPrice, onClose, onClick }) => {
    const [values, setValues] = useState({
        provider: 'cardkingdom',
        operation: 'percentage',
        value: 0.0
    })

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        })
    }

    const SendButton = ({ onClick, values }) => {
        return (
            <Button type='submit' secondary value='Update price' onClick={() => onClick(values)}>
                <Translation id='UPDATE_PRICE_FROM_PRICE_PROVIDER_CARDKINGDOM'
                             defaultMessage='Update price from cardkingdom' />
            </Button>
        )
    }

    return (
        <Modal title='Update price from price provider' onClose={onClose}
               footer={<SendButton onClick={onClick} values={values} />}>
            <div className='row'>
                <div className='col-12'>
                    <ul>
                        <li>
                            <Translation id='UPDATE_PRICE_FROM_PRICE_PROVIDER_P1'
                                         defaultMessage='Not all products are available in the price provider' />
                        </li>
                        <li>
                            <Translation id='UPDATE_PRICE_FROM_PRICE_PROVIDER_P2'
                                         defaultMessage='It will update the price of all products that are available' />
                        </li>
                        <li>
                            <Translation id='UPDATE_PRICE_FROM_PRICE_PROVIDER_P3'
                                         defaultMessage='It will not update the price of products that are not available' />
                        </li>
                        <li>
                            <Translation id='UPDATE_PRICE_FROM_PRICE_PROVIDER_P4'
                                         defaultMessage='Please, review the price of the products that are not updated' />
                        </li>
                    </ul>
                </div>
            </div>
            <div className='row'>
                <form id='updatePriceFromProviderForm'>
                    <div className='mb-3'>
                        <label className='form-label' htmlFor='price'>
                            <Translation id='UPDATE_PRICE_FROM_PRICE_PROVIDER_SET_PROVIDER'
                                         defaultMessage='Set price provider' />
                        </label>
                        <select className='form-select' aria-label='provider' name='provider' disabled
                                defaultValue={'cardkingdom'}>
                            <option value='cardkingdom'>Cardkingdom</option>
                        </select>
                    </div>
                    <div className='mb-3'>
                        <label className='form-label' htmlFor='operation'>
                            <Translation id='UPDATE_PRICE_FROM_PRICE_PROVIDER_SET_OPERATION'
                                         defaultMessage='Set operation' />
                        </label>
                        <select className='form-select' aria-label='operation' name='operation' disabled
                                defaultValue={'percentage'}>
                            <option value='percentage'>+ %</option>
                        </select>
                    </div>
                    <div className='mb-3'>
                        <label className='form-label' htmlFor='value'>
                            <Translation id='UPDATE_PRICE_FROM_PRICE_PROVIDER_SET_VALUE' defaultMessage='Set value' />
                        </label>
                        <input className='form-control' type='number' id='value' name='value' value={values.value}
                               onChange={handleChange} min='-100' max='100' step='0.1' />
                    </div>
                </form>
                {productsWithoutPrice.length > 0 &&
                    productsWithoutPrice.map((product) => {
                        return (
                            <div key={product.product_id}>
                                <div className='alert alert-warning' role='alert'>
                                    <div className='row'>
                                        <div className='col-10'>
                                            <Translation
                                                id='UPDATE_PRICE_FROM_PRICE_PROVIDER_PRODUCT_NOT_FOUND'
                                                defaultMessage='Product {name} not found in the price provider'
                                                values={{ name: product.name }}
                                            />
                                        </div>
                                        <div className='col-2'>
                                            <LocalizedLink routeId={'ROUTE_USER_DASHBOARD'} target='_blank'
                                                           params={{ view: 'edit-item-sale', id: product.id }}>
                                                <div className='border-left-line'>
                                                    <span className='material-icons-outlined'
                                                          style={{ padding: '16px' }}>
                                                        call_made
                                                    </span>
                                                </div>
                                            </LocalizedLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
            </div>
        </Modal>
    )
}
export const defaultSaleItemPageSize = 12
class SaleItemsScreen extends React.Component {
    state = {
        gridView: false,
        filters: {
            ...getDefaultFilters()
        },
        orderBy: {
            label: 'Name asc',
            value: {
                column: 'products.name',
                direction: 'asc'
            }
        },
        showProviderPriceModal: false,
        shouldDisplayProductCategoryFilter: false,
        selectedMaterials: [],
        selectedMaterialsData: []
    }

    constructor() {
        super()
        this.searchSaleItems = debounce(this.searchSaleItems.bind(this), 400)
    }

    componentDidMount() {
        if (this.props.isBusiness) this.setState({ shouldDisplayProductCategoryFilter: true })
        if (!this.isUserListEqualToCurrentLogged()) {
            this.props.fetchDistinctUserPublicInfo()
            this.setState({gridView:true}) //activamos grid view cuando vemos listados de otros usuarios, en nuestors listados se ve modo lista
        }
        this.setState({
            shouldDisplayProductCategoryFilter: this.shouldDisplayProductCategoryFilter()
        })

        this.loadQueryParamsToFiltersAndOrderBy(
            () => {
                const isSameLastFilters = isEqual(parseFiltersToLaravel(this.state.filters), this.props.lastFiltersUsed)

                if (
                    parseInt(this.getSellerId()) !== parseInt(this.props.lastSellerId)
                    || !this.props.saleItems?.length
                    || isEqual(this.state.filters, getDefaultFilters())
                    || !isSameLastFilters
                ) {
                    return this.searchSaleItems()
                }
            })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.collectableContext !== this.props.collectableContext) {
            this.resetFiltersValue(() => this.searchSaleItems())
        }
        if (prevProps.userListInfoDistinctCurrentUser?.isBusiness !== this.props.userListInfoDistinctCurrentUser?.isBusiness || this.props.isBusiness !== prevProps.isBusiness) {
            this.setState(
                {
                    shouldDisplayProductCategoryFilter: this.shouldDisplayProductCategoryFilter()
                }
            )
        }

        if (!isEqual(prevState.filters, this.state.filters) || !isEqual(prevState.orderBy, this.state.orderBy)) {
            this.transformFiltersAndOrderByToQueryParams()
        }
    }

    transformFiltersAndOrderByToQueryParams() {
        const isFilterActive = (value) => {
            if (value === null || value === undefined || value === '') {
                return false
            }

            if (typeof value === 'object' && !value.value) {
                return false
            }
            return true
        }

        const queryParams = new URLSearchParams()

        const filters = {}

        // Set each filter dynamically in query params
        Object.entries(this.state.filters).forEach(([key, value]) => {
            if (isFilterActive(value)) {
                filters[key] = value
            }
        })

        queryParams.set('filters', JSON.stringify(filters))

        if (this.state.orderBy) {
            queryParams.set('orderBy', JSON.stringify(this.state.orderBy))
        }

        this.props.history.replace({ pathname: this.props.location.pathname, search: queryParams.toString() })
    }


    loadQueryParamsToFiltersAndOrderBy(callback = null) {
        const queryParams = new URLSearchParams(this.props.location.search)

        const filters = queryParams.get('filters') ? JSON.parse(queryParams.get('filters')) : {}

        const orderBy = queryParams.get('orderBy') ? JSON.parse(queryParams.get('orderBy')) : null

        this.setState(
            { filters: { ...this.state.filters, ...filters }, orderBy: orderBy ?? this.state.orderBy },
            () => {
                if (callback) {
                    callback()
                }
            }
        )
    }


    shouldDisplayProductCategoryFilter = () => {
        const shouldDisplayProductCategoryFilter = (this.isUserListEqualToCurrentLogged() && this.props.isBusiness) || this.props.userListInfoDistinctCurrentUser?.isBusiness
        return Boolean(shouldDisplayProductCategoryFilter)
    }

    resetFiltersValue(callback = null) {
        this.setState(
            {
                ...this.state,
                filters: {
                    ...getDefaultFilters()
                }
            },
            () => {
                if (callback) {
                    callback()
                }
            }
        )
    }

    getSellerId() {
        return this.props.match.params.id ?? this.props.userIdLogged
    }

    isUserListEqualToCurrentLogged() {
        //Dont put triple equal on this line
        return this.props.match.params.id ? this.props.match.params.id == this.props.userIdLogged : true
    }

    isUserItemEqualToCurrentLogged(item) {
        return this.props.userIdLogged == item.user_id
    }

    canAddItem() {
        return this.props.isLogged && this.isUserListEqualToCurrentLogged()
    }

    selectMaterial = (product, callback) => {
        this.setState(
            {
                selectedMaterials: this.state.selectedMaterials.concat(product.id),
                selectedMaterialsData: [...this.state.selectedMaterialsData, { ...product }]
            },
            () => {
                if (typeof callback === 'function') callback()
            }
        )
    }

    selectAllMaterials = () => {
        //cant call searchsaleitems because the debounce make it not works
        this.props.fetchSaleItems(1, 100000, parseFiltersToLaravel(this.state.filters)).then((response) => this.selectBulkMaterials(this.props.saleItems))
        // this.searchSaleItems(1, 100000).then((response) => {
        //     console.log('test2 ya he pasado por el then')
        //     // console.log('SELECT ALL', products)

        //     this.selectBulkMaterials(this.props.saleItems)
        // })
    }
    selectBulkMaterials = (products) => {
        // console.log('selecting on bulk', products, products.length)
        if (products && products.length > 0) {
            products.forEach((product) => {
                // console.log('product on foreach', product)
                if (!this.state.selectedMaterials.includes(product.id)) {
                    this.selectMaterial(product)
                }
            })
        }
    }
    //ReVIEW
    // removeBulkMaterials = (products) => {
    //     if (products && products.length) {
    //         products.forEach((product) => {
    //             // if (this.state.selectedMaterials.includes(product.productId) && product.centersCount === this.props.centers.length) {
    //             if (this.state.selectedMaterials.includes(product.productId) && !product.cantBeSelected) {
    //                 this.setState({
    //                     selectedMaterials: this.state.selectedMaterials.filter((material) => material !== product.productId),
    //                     selectedMaterialsData: this.state.selectedMaterialsData.filter((material) => material.productId !== product.productId),
    //                 })
    //             }
    //         })
    //     }
    // }
    removeAllMaterials = () => {
        this.setState({ selectedMaterials: [], selectedMaterialsData: [] })
    }

    removeMaterial = (product, callback) => {
        this.setState(
            {
                selectedMaterials: this.state.selectedMaterials.filter((material) => material !== product.id),
                selectedMaterialsData: this.state.selectedMaterialsData.filter((material) => material.id !== product.id)
            },
            () => {
                if (typeof callback === 'function') callback()
            }
        )
    }
    searchSaleItems = (page = 1, pageSize = defaultSaleItemPageSize) => {
        return this.props.fetchSaleItems(page, pageSize, parseFiltersToLaravel(this.state.filters), this.state.orderBy?.value)
    }

    setFilterValue = (filterKey, value) => {
        this.setState({
            filters: {
                ...this.state.filters,
                [filterKey]: value
            }
        }, () => {
            this.searchSaleItems(1, defaultSaleItemPageSize)
        })
    }

    setOrderByValue = (orderBy) => {
        this.setState({
                orderBy
            }, () => this.searchSaleItems(1, defaultSaleItemPageSize)
        )
    }

    addToCartAndRefreshSaleItem = (id,qty) => {
        this.props.addToCart(id, qty, this.props.intl).then(() => this.props.fetchSaleItemByIdPublic(id))
    }
    render() {
        if (this.props.isLogged && this.isUserListEqualToCurrentLogged() && !this.props.bankData?.validated) {
            return (
                <div className='col-12'>
                    <Alerts alert={'FILL_FINANCIAL_INFO_TO_TRADE'} status={'warning'} />
                </div>
            )
        }
        const saleItems = this.props.saleItems ?? []
        //Dont put triple equal on this line
        const userListIsSameWhichMe = this.props.match.params.id ? this.props.match.params.id == this.props.userIdLogged : true

        const title = this.isUserListEqualToCurrentLogged() ? 'My sale list' : `${this.props.userListInfoDistinctCurrentUser?.name || ''} sale list`

        return (
            <div>
                <div className="container-fluid">
                    {this.props.isBusiness && this.state.showProviderPriceModal && (
                        <ProviderPriceModal
                            productsWithoutPrice={this.props.productsWithoutPrice}
                            onClose={() => this.setState({ showProviderPriceModal: false })}
                            onClick={({ provider, operation, value }) => {
                                this.props
                                    .updatePriceFromPriceProvider(provider, operation, value, this.state.selectedMaterials)
                                    .then(() => this.searchSaleItems(this.props.saleTableInfo.page, this.props.saleTableInfo.pageSize))
                                    .finally(() => this.props.productsWithoutPrice.length === 0 && this.setState({ showProviderPriceModal: false }))
                            }}
                        />
                    )}
                    <div className="row" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className="col-12 col-md-4">
                            <h2>{title}</h2>
                        </div>
                        {this.canAddItem() && (
                            <div className="col-12 col-md-8 ">
                                <div className="row" style={{ justifyContent: 'end', alignItems: 'center' }}>
                                    {this.props.isBusiness && (
                                        <React.Fragment>
                                            <div className="col-6 col-lg-3">
                                                <Button secondary onClick={() => this.selectAllMaterials()} s>
                                                    <Translation id="select_all" defaultMessage="Select all" />
                                                </Button>
                                            </div>
                                            <div className="col-6 col-lg-3">
                                                <Button secondary onClick={() => this.removeAllMaterials()}>
                                                    <Translation id="remove_all" defaultMessage="Remove all" />
                                                </Button>
                                            </div>
                                            <div className="col-12 col-md-3">
                                                <Button
                                                    secondary
                                                    className="h-100"
                                                    onClick={() => {
                                                        this.setState({ showProviderPriceModal: true })
                                                    }}
                                                >
                                                    <Translation id="UPDATE_PRICE_FROM_PRICE_PROVIDER" defaultMessage="Update price" />
                                                </Button>
                                            </div>
                                        </React.Fragment>
                                    )}
                                    <div className="col-12 col-md-3 col-lg-2">
                                        <LocalizedLink routeId={'ROUTE_USER_DASHBOARD'} params={{ view: 'add-to-sale', id: '' }}>
                                            <Button icon="plus-circle">
                                                <Translation id="NEW_ITEM" defaultMessage="New item" />
                                            </Button>
                                        </LocalizedLink>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="container-fluid" id={`${this.state.gridView ? 'section-grid-view' : ''}`}>
                    <h4 className="card-title">
                        <Translation id="filters" defaultMessage="Filters" />
                    </h4>
                    <div className="mt-3 mb-3">
                        <SaleItemsFilters
                            filters={this.state.filters}
                            orderBy={this.state.orderBy}
                            expansionOptions={this.props.expansionOptions}
                            rarityOptions={this.props.rarityOptions}
                            productsCategoriesOptions={this.props.productsCategoriesOptions}
                            availableLangs={this.props.availableLangs}
                            availableStatus={this.props.availableStatus}
                            booleanOptions={this.props.booleanOptions}
                            availableOrderByOptions={this.props.availableOrderByOptions}
                            shouldDisplayProductCategoryFilter={this.state.shouldDisplayProductCategoryFilter}
                            setFilters={this.setFilterValue}
                            setOrderBy={this.setOrderByValue}
                            onSubmit={this.searchSaleItems}
                            onReset={() => this.resetFiltersValue(() => this.searchSaleItems(1, defaultSaleItemPageSize))}
                        />
                        <div class="grid-switch mt-3" style={{ maxWidth: '120px', display: 'flex', flexDirection: 'row' }}>
                            <Button
                                secondary={!this.state.gridView ? true : false}
                                primary={this.state.gridView ? true : false}
                                onClick={() => this.setState({ gridView: !this.state.gridView })}
                                style={{ borderTopRightRadius: '0px' }}
                            >
                                <span class="material-symbols-outlined">grid_view</span>
                            </Button>
                            <Button
                                secondary={this.state.gridView ? true : false}
                                primary={!this.state.gridView ? true : false}
                                onClick={() => this.setState({ gridView: !this.state.gridView })}
                            >
                                <span class="material-symbols-outlined">view_list</span>
                            </Button>
                        </div>
                    </div>
                    <div className={`card-list`}>
                        <InfiniteScroll
                            dataLength={saleItems.length}
                            next={() => this.props.saleTableInfo.page < this.props.saleTableInfo.pagesCount && this.searchSaleItems(this.props.saleTableInfo.page + 1, defaultSaleItemPageSize)}
                            hasMore={this.props.saleTableInfo.page < this.props.saleTableInfo.pagesCount}
                            loader={<h4>Loading...</h4>}
                            className="sk-hits"
                            endMessage={
                                <p style={{ textAlign: 'center' }}>
                                    <b>You have seen it all</b>
                                </p>
                            }
                        >
                            {saleItems.map((item) => {
                                return (
                                    <React.Fragment>
                                        {this.props.isBusiness && (
                                            <input
                                                type="checkbox"
                                                onClick={() => this.selectMaterial(item)}
                                                key={'checkbox-' + item.id + this.state.selectedMaterials.includes(item.id)}
                                                defaultChecked={this.state.selectedMaterials.includes(item.id)}
                                                style={{
                                                    position: 'absolute',
                                                    zIndex: 100,
                                                    height: '20px',
                                                    width: '20px',
                                                }}
                                            />
                                        )}
                                        {this.state.gridView && (
                                            <SaleItemSimplifiedResult
                                                type="sale"
                                                addingToCart={this.props.addingToCart}
                                                product={item.product}
                                                key={item.id}
                                                item={item}
                                                onShowUpLogin={this.props.onShowUpLogin}
                                                isLogged={this.props.isLogged}
                                                userName={this.props.userName}
                                                bankData={this.props.bankData}
                                                isVerified={this.props.verified}
                                                selectedMaterials={this.state.selectedMaterials}
                                                selectMaterial={(item) => this.selectMaterial(item)}
                                                showCheckbox={true}
                                                isUserItemEqualToCurrentLogged={this.isUserListEqualToCurrentLogged(item)}
                                                updateQty={(id, qty) => {
                                                    this.props
                                                        .updateQtyItemFromSale(id, qty)
                                                        .then(() => this.searchSaleItems(this.props.saleTableInfo.page, this.props.saleTableInfo.pageSize))
                                                }}
                                                addToCart={this.addToCartAndRefreshSaleItem}
                                                deleteSaleItem={(id, original) =>
                                                    confirmDeleteItem(id, original, this.props.deleteItemFromSale, this.props.intl).then(() =>
                                                        this.searchSaleItems(this.props.saleTableInfo.page, this.props.saleTableInfo.pageSize)
                                                    )
                                                }
                                            />
                                        )}
                                        {!this.state.gridView && (
                                            <RenderSaleItemMobile
                                                addingToCart={this.props.addingToCart}
                                                key={item.id}
                                                item={item}
                                                intl={this.props.intl}
                                                onShowUpLogin={this.props.onShowUpLogin}
                                                isLogged={this.props.isLogged}
                                                userName={this.props.userName}
                                                bankData={this.props.bankData}
                                                isVerified={this.props.verified}
                                                selectedMaterials={this.state.selectedMaterials}
                                                selectMaterial={(item) => this.selectMaterial(item)}
                                                showCheckbox={true}
                                                isUserItemEqualToCurrentLogged={this.isUserListEqualToCurrentLogged(item)}
                                                updateQty={(id, qty) => {
                                                    this.props
                                                        .updateQtyItemFromSale(id, qty)
                                                        .then(() => this.searchSaleItems(this.props.saleTableInfo.page, this.props.saleTableInfo.pageSize))
                                                }}
                                                addToCart={this.addToCartAndRefreshSaleItem}
                                                deleteSaleItem={(id, original) =>
                                                    confirmDeleteItem(id, original, this.props.deleteItemFromSale, this.props.intl).then(() =>
                                                        this.searchSaleItems(this.props.saleTableInfo.page, this.props.saleTableInfo.pageSize)
                                                    )
                                                }
                                            />
                                        )}
                                    </React.Fragment>
                                )
                            })}
                        </InfiniteScroll>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(SaleItemsScreen)))
