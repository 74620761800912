import React from 'react'
import Translation from '../global/Translation'

import LocalizedLink from '../global/LocalizedLink'

import MenuLinks from './MenuLinks'
import ProfileLinks from './ProfileLinks'
export default class ResponsiveProfileMenu extends React.Component {
    render() {
        const userProfile = this.props.isLogged ? (
            <div>
                <ProfileLinks responsive={true} />
            </div>
        ) : (
            <li className="nav-item">
                <span className="nav-link" onClick={this.props.onShowUpLogin}>
                    <Translation id="LOGIN" defaultMessage="Login" />
                </span>
            </li>
        )

        return (
            <nav id="mobile-main-menu" className="d-lg-none navbar navbar-expand-lg navbar-light">
                <div className="navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        {userProfile}
                        <MenuLinks responsive={true} />
                    </ul>
                </div>
            </nav>
        )
    }
}
