import { fetchBuys, fetchOrders, fetchSales } from './../../store/orders/actions'
import { connect } from 'react-redux'
import { getOrders } from '../../store/orders/reducers'
import { push } from 'connected-react-router'


import Orders from './Orders'
import { getUserName } from '../../store/login/reducers'

const mapStateToProps = (state, { match }) => {
    return {
        // id: match.params.id,
        // product: byProductId(state, match.params.id),
        orders: getOrders(state),
        userName:getUserName(state)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchOrders: () => {
            return dispatch(fetchOrders())
        },
        fetchSales: () => {
            return dispatch(fetchSales())
        },
        fetchBuys: () => {
            return dispatch(fetchBuys())
        },
        redirect: (url) => Promise.resolve(dispatch(push(url))),
    }
}

const OrdersContainer = connect(mapStateToProps, mapDispatchToProps)(Orders)

export default OrdersContainer
