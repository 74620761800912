import * as api from '../api'
import { getToken, getUserId } from '../login/reducers'
import { getLanguage } from '../ui/reducers'
import { goBack } from 'connected-react-router'
import { fetchProductBuys, fetchProductSales } from '../products/actions'

export const fetchBuyItems = (page, pageSize, filters, orderBy) => (dispatch, getState) => {
    const userId = getUserId(getState())

    dispatch({ type: 'FETCH_BUY_ITEMS_REQUEST', page, pageSize, filters, orderBy, lastUserId: userId })

    return api
        .fetchBuyItems(getToken(getState()), page, pageSize, filters, orderBy)
        .then((response) => {
            return dispatch({
                type: 'FETCH_BUY_ITEMS_SUCCESS',
                buyItems: response.data.data,
                expansionsList: response.data.expansionsList,
                rarityList: response.data.rarityList,
                filters,
                orderBy,
            })
        })
        .catch((error) => {
            return dispatch({ type: 'FETCH_BUY_ITEMS_FAILURE', response: error.response })
        })
}

export const fetchBuyItemsFromUser = (userToFilterId, page, pageSize, filters, orderBy) => (dispatch, getState) => {
    dispatch({
        type: 'FETCH_BUY_ITEMS_FROM_USER_REQUEST',
        page,
        pageSize,
        filters,
        orderBy,
        lastUserId: userToFilterId
    })
    return api
        .fetchBuyItemsFromUser(getToken(getState()), userToFilterId, page, pageSize, filters, orderBy)
        .then((response) => {
            return dispatch({
                type: 'FETCH_BUY_ITEMS_FROM_USER_SUCCESS',
                buyItems: response.data.data,
                expansionsList: response.data.expansionsList,
                rarityList: response.data.rarityList,
                filters,
                orderBy,
            })
        })
        .catch((error) => {
            return dispatch({ type: 'FETCH_BUY_ITEMS_FROM_USER_FAILURE', response: error.response })
        })
}

export const fetchBuyItemById = (itemId) => (dispatch, getState) => {
    const locale = getLanguage(getState())
    dispatch({ type: 'FETCH_BUY_ITEM_REQUEST', itemId })
    return api
        .fetchBuyItemById(getToken(getState()), itemId)
        .then((response) => {
            return dispatch({ type: 'FETCH_BUY_ITEM_SUCCESS', buyItem: response.data })
        })
        .catch((error) => {
            return dispatch({ type: 'FETCH_BUY_ITEM_FAILURE', response: error.response })
        })
}

export const addToBuy = (buyItem, shouldGoBack) => (dispatch, getState) => {
    const locale = getLanguage(getState())
    dispatch({ type: 'ADD_TO_BUY_REQUEST', buyItem })
    return api
        .addToBuy(getToken(getState()), buyItem)
        .then((response) => {
            dispatch(fetchProductSales(buyItem.card, locale))
            dispatch(fetchProductBuys(buyItem.card, locale))
            dispatch({ type: 'ADD_TO_BUY_SUCCESS', buyItem: response.data })
            if (shouldGoBack) {
                dispatch(goBack())
            }
            return response.data
        })
        .catch((error) => {
            let errors = null
            if (error.response && error.response.status === 422) {
                errors = error.response.data.errors
            }
            return dispatch({ type: 'ADD_TO_BUY_FAILURE', response: error.response, errors })
        })
}
export const editItemFromBuy = (buyItem) => (dispatch, getState) => {
    const locale = getLanguage(getState())
    dispatch({ type: 'EDIT_ITEM_FROM_BUY_REQUEST', buyItem })
    return api
        .editItemFromBuy(getToken(getState()), buyItem)
        .then((response) => {
            dispatch(goBack())
            return dispatch({ type: 'EDIT_ITEM_FROM_BUY_SUCCESS' })
        })
        .catch((error) => {
            let errors = null
            if (error.response && error.response.status === 422) {
                errors = error.response.data.errors
            }
            return dispatch({ type: 'EDIT_ITEM_FROM_BUY_FAILURE', response: error.response, errors })
        })
}

export const deleteItemFromBuy = (itemId) => (dispatch, getState) => {
    const locale = getLanguage(getState())
    dispatch({ type: 'REMOVE_FROM_BUY_REQUEST', itemId })
    return api
        .deleteItemFromBuy(getToken(getState()), itemId)
        .then((response) => {
            return dispatch({ type: 'REMOVE_FROM_BUY_SUCCESS' })
        })
        .catch((error) => {
            let errorCode = 'SOMETHING_WENT_WRONG'
            if (error.response.data.err) {
                errorCode = error.response.data.err
            }
            return dispatch({ type: 'REMOVE_FROM_BUY_FAILURE', errorCode })
        })
}


export const updateQtyItemFromBuy = (itemId, qty) => (dispatch, getState) => {
    const locale = getLanguage(getState())
    dispatch({ type: 'UPDATE_QTY_ITEM_OF_BUY_REQUEST', itemId, qty })
    return api
        .updateQtyItemFromBuy(getToken(getState()), itemId, qty)
        .then((response) => {
            return dispatch({ type: 'UPDATE_QTY_ITEM_OF_BUY_SUCCESS' })
        })
        .catch((error) => {
            return dispatch({ type: 'UPDATE_QTY_ITEM_OF_BUY_FAILURE', response: error.response })
        })
}
