import Select from 'react-select'
import React from 'react'
import ResponsiveProfileMenuContainer from './ResponsiveProfileMenuContainer'
import IconButtonsContainer from './IconButtonsContainer'
import LoadingBar from 'react-redux-loading-bar'
import { injectIntl } from 'react-intl'
import { HeaderSearch } from './HeaderSearch'
import Translation from '../global/Translation'

import {
    COLLECTABLE_CONTEXT_OPTIONS,
    getCollectableContextOptionByContextValue
} from '../../store/products/CollectableContext'

class Header extends React.Component {

    render() {
        let input
        const handleSubmit = (event) => {
            const url = this.props.intl.formatMessage({ id: 'ROUTE_CATALOG' })
            event.preventDefault()
            this.props.onSubmit(url + '?q=' + input.value)
            input.value = ''
        }
        const responsiveProfileMenu = this.props.showMenu ? <ResponsiveProfileMenuContainer /> : null
        let routeCatalog = this.props.intl.formatMessage({ id: 'ROUTE_CATALOG' })
        let isInCatalog = this.props.currentUrl === routeCatalog



        return (
            <React.Fragment>
                <header id="header">
                    <div id="main-header">
                        <div className="container-fluid">
                            <div className="row no-gutters align-items-center" style={{ height: '80px' }}>
                                <div className="d-none d-xl-block col">
                                    <h5 style={{ margin: '0', maxWidth: '360px', fontFamily: 'unbounded' }}>
                                        <Translation id="explore_candc" defaultMessage="Explore C&C database and expand your collection"></Translation>
                                    </h5>

                                </div>
                                <div className={'col'}>
                                    {this.props.collectableContext && <HeaderSearch
                                        intl={this.props.intl}
                                        isInCatalog={isInCatalog}
                                        handleSubmit={handleSubmit}
                                        setRef={(node) => {
                                            input = node
                                        }}
                                    />}
                                </div>
                                <div className={'col'}>
                                    <IconButtonsContainer />
                                </div>
                            </div>
                        </div>
                        <LoadingBar className="loading-bar" showFastActions />
                    </div>
                </header>
            </React.Fragment>
        )
    }
}

export default injectIntl(Header)
