import React from 'react'
import Translation from '../global/Translation'
import MiniCartContainer from '../mini-cart/MiniCartContainer'
import ProfileModalContainer from '../profile-modal/ProfileModalContainer'
import MiniNotificationsContainer from '../notifications/mini-notifications/MiniNotificationsContainer'
import DisplayPrice from '../product/DisplayPrice'

export default class IconButtons extends React.Component {

    intervalSession = null

    componentWillUnmount() {
        if (this.intervalSession) {
            clearInterval(this.intervalSession)
        }
    }

    isInChat() {
        return this.props.currentUrl?.pathname?.includes('chat') || false
    }

    render() {

        // Cart
        const totalBadge = this.props.isLogged && this.props.totalQty ?
            <span className='badge badge-pill badge-secondary'>{this.props.totalQty}</span> : null

        // Initials
        let initials = this.props.isLogged && this.props.username.split(' ')[0].charAt(0).toUpperCase()
        if (this.props.isLogged && this.props.username.split(' ')[1]) {
            (initials = initials + this.props.username.split(' ')[1].charAt(0).toUpperCase())
        }

        // Chat
        // if (!this.intervalSession && this.props.isLogged && !this.isInChat()) {
        //      this.intervalSession = setInterval(() => {
        //          this.props.fetchUnreadMessages()
        //      }, 30000)
        // }
        if (this.intervalSession && (!this.props.isLogged || this.isInChat())) {
            clearInterval(this.intervalSession)
        }

        // Notifications
        const totalMiniNotifications = this.props.qtyNotifications + this.props.qtyPendingTasks
        const totalNotificationBadge = this.props.isLogged && totalMiniNotifications > 0 ?
            <span className='badge badge-pill badge-secondary'>{totalMiniNotifications}</span> : null

        const notifications =
            this.props.isLogged ? (
                <li>
              <span
                  id='cart-doll'
                  className='cart'
                  data-cy='user_menu__notifications_doll'
                  onClick={() => {
                      this.props.onToggleNotifications(this.props.showNotifications)
                  }}
              >
                  <span className='material-icons-outlined' style={{ color: 'white', fontSize: '32px' }}>
                      notifications
                  </span>
                  {totalNotificationBadge}
              </span>
                </li>
            ) : null


        const miniCart = this.props.isLogged ? (
            <li>
                <span
                    id='cart-doll'
                    className='cart'
                    data-cy='user_menu__cart_doll'
                    onClick={() => {
                        this.props.onToggleCart(this.props.showCart)
                    }}
                >
                    {/* <img alt="" className={this.props.isAddingCallApi ? 'icon shake-horizontal-slow shake-constant' : 'icon'} src={iconCart} /> */}
                    {/* <i className="fal fa-shopping-cart" style={{ color: 'lightgray', fontWeight: 300, fontSize: '24px' }} /> */}
                    <span className='material-icons-outlined' style={{ color: 'white', fontSize: '32px' }}>
                        shopping_cart
                    </span>
                    {totalBadge}
                </span>
            </li>
        ) : null

        const userBadge = this.props.isLogged ? (
            <li>
                <span
                    id="profile-doll"
                    className="cart"
                    data-cy="user_menu__profile_doll"
                    onClick={() => {
                        this.props.onToggleProfile(this.props.showingProfileModal)
                    }}
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
                >
                    {this.props.isLogged && (
                        this.props.userProfileLogoUrl ? (
                                <img alt='' className='circle' style={{ height: '3rem', width: '3rem', marginRight: '8px' }} src={this.props.userProfileLogoUrl} />
                            ) : (
                            <div className="circle" style={{ marginRight: '8px' }}>
                                <span className="initials">{initials}</span>
                            </div>
                            )
                    )}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span className="d-md-block d-none">{this.props.username}</span>
                        <span className="d-lg-block d-none">
                            Credit:{' '}
                            <span style={{ color: this.props.credit >= 0 ? '#62BD5A' : '#ff6669' }}>
                                <DisplayPrice price={this.props.credit} showZero={true}></DisplayPrice> <span className="caret"></span>
                            </span>
                        </span>
                    </div>
                    {/* <i className="fal fa-user" style={{ color: 'lightgray', fontWeight: 300, fontSize: '24px' }} /> */}
                    <span className="material-icons-outlined" style={{ color: 'white', fontSize: '32px' }}>
                        expand_more
                    </span>
                    {/* <img alt="" src={this.props.userimage || iconProfile} className="icon" style={{ borderRadius: this.props.userimage ? '100%' : '' }} /> */}
                </span>
            </li>
        ) : (
            <li>
                <span className="cart" onClick={this.props.onShowUpLogin} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    {/* <Button size="small"> */}
                    <div className="circle" style={{ marginRight: '16px' }}>
                        <span className="initials">
                            <span className="material-icons-outlined" style={{ fontSize: '24px' }}>
                                login
                            </span>
                        </span>
                    </div>
                    <Translation id="Login_or_register" defaultMessage="Login / Register" />
                    <span className="material-icons-outlined d-none d-md-block" style={{ color: 'white', fontSize: '32px' }}>
                        chevron_right
                    </span>
                </span>
            </li>
        )

        return (
            <nav id='top-side-menu'>
                {this.props.isLogged && <ProfileModalContainer />}
                {this.props.isLogged && <MiniCartContainer />}
                {this.props.isLogged && <MiniNotificationsContainer />}

                <ul style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {notifications}
                    {miniCart}
                    <div style={{
                        width:'max-content',
                        background: '#101823',
                        minWidth: '60px',
                        display: 'inline-block',
                        borderRadius: '16px',
                        height: '60px',
                        padding: '8px 16px',
                        cursor: 'pointer'
                    }}>{userBadge}</div>
                </ul>
            </nav>
        )
    }
}
