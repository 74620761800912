import React, { useEffect, useState } from 'react'

export const FileInput = ({
                              onFileSelect,
                              errors,
                              id,
                              name,
                              accept = '*',
                              label,
                              src,
                              readOnly,
                              required,
                              showRequired = true,
                              onFileDelete,
                              ...rest
                          }) => {

    const [previewFileSrc, setPreviewFileSrc] = useState(null);

    useEffect(() => {
        if (src) {
            setPreviewFileSrc(src)
        }
    }, [src])

    const handleFileInput = (e) => {
        const fileSelected = e.target.files[0];
        onFileSelect(fileSelected)
        setPreviewFileSrc(URL.createObjectURL(e.target.files[0]))
    }
    const handleFileDelete = (e) => {
        e.preventDefault();
        setPreviewFileSrc(null)
        onFileDelete();
    }

    return (
        <div className=''>
            <label className='label__input'>
                {label} {(showRequired && required) ? '(*)' : ''}
            </label>
            {previewFileSrc && <React.Fragment>
                <div style={{
                    maxWidth: '170px',
                    padding: '8px 0',
                    margin: '0 auto'
                }}>
                    <img src={previewFileSrc} alt={label} className='w-100 cursor-pointer' />
                </div>
                <p className='py-2'>
                    <button type='button' className='mx-2' color='warn' onClick={handleFileDelete}>
                        <mat-icon>delete</mat-icon>
                    </button>
                </p>
            </React.Fragment>
            }

            {!previewFileSrc && <input
                required={required}
                id={id}
                name={name}
                accept={accept}
                {...rest}
                className={readOnly ? 'form__input--readonly' : 'form__input'}
                type='file'
                autoComplete='off'
                onChange={handleFileInput}
            />}
            {errors && errors[name] ? <span className='form__error'> {errors[name].join('. ')}</span> : null}
            <span className='focus-border' />
        </div>
    )
}
