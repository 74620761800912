import React, { useEffect } from 'react'
import { injectIntl } from 'react-intl'
import LocalizedLink from '../components/global/LocalizedLink'
import Translation from '../components/global/Translation'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../components/global/Button'
import { resendVerificationEmail } from '../store/login/actions'
import { push } from 'connected-react-router'
import { getVerified } from '../store/login/reducers'

const ThanksYouForRegisterScreen = props => {

    const name = useSelector(state => state.login.username)
    const isEmailVerified = useSelector(state => getVerified(state))

    const dispatch = useDispatch();

    useEffect(() => {
        if (isEmailVerified) {
            dispatch(push('/'));
        }
    })

    const handleClickResendEmailButton = () => {
      dispatch(resendVerificationEmail(name))
    }

    const email = process.env.REACT_APP_EMAIL_SUPPORT;

    return (
        <div>
            <div className="container-fluid registerForm mb-5" id="registerForm">
                <div className="row">                   
                    <div className="col-12">
                        <div className="">
                            <div className="q-top d-none d-xl-block">
                                <i className="fas fa-thumbs-up" style={{padding: '0 15px'}}></i>
                                Your registration was successful                           
                            </div>
                            <div className="row my-3">
                                <div className="col-12 lab">
                                    <h2 className="text-center">What´s up, {name} !</h2>
                                    <p className="col-10 offset-1 mt-3">
                                        Hey Buddy! Welcome to the Cards & Collections community.</p>
                                    <p className="col-10 offset-1 mt-3">
                                        You are so excited, right?
                                        You are closer to completing the registration.</p>
                                    <p className="col-10 offset-1 mt-3">
                                        It is easy, we have sent you a spectacular email. Go to your email, verify and enjoy the experience.<br/>
                                        As you know,email goes bonkers, check your spam folder if you did not receive our email, sometimes it happens!.<br/>
                                    </p>
                                    <p className="col-10 offset-1 mt-3">                                
                                        If you have any question, contact us at <a href={ "mailto:" + email }>{email}</a><br/>
                                    </p>
                                    <p className="col-10 offset-1 mt-3">See you.</p> 
                                    <p className="col-10 offset-1 mt-3">The adventure has just begun.......</p>

                                    <h2 className="col-10 offset-1 mt-3">Enjoy the experience !</h2>
                                    <LocalizedLink routeId="ROUTE_HOME" className="col-10 offset-1 mt-3" >
                                        <Translation id="BACK_TO_MAIN_PAGE" defaultMessage="Back to main page" />
                                    </LocalizedLink>
                                    <p className="col-10 offset-1 mt-3">
                                        <Button type="submit" className="btn btn-link register_button" value="ResendEmail" onClick={handleClickResendEmailButton}>
                                            <Translation id="RESEND_EMAIL_VERIFICATION" defaultMessage="Resend verify email" />
                                        </Button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}   

export default (injectIntl((ThanksYouForRegisterScreen)))
