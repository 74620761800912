import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import OrdersContainer from '../components/orders/OrdersContainer'
import { getBankData } from '../store/login/reducers'
import Alerts from '../components/alerts/Alerts'
import Translation from '../components/global/Translation'
import Select from 'react-select'

const transactionOptions = [
    {label: 'My purchases', value: 'BUY'},
    {label: 'My sales', value: 'SALES'}
]

const TransactionsScreen = (props,intl) => {

    const bankData = useSelector(state => getBankData(state))

    const [type, setType] = useState(transactionOptions[0]);


    return (
       <div>
           <div className='container-fluid'>
               <div className='row'>
                   {/* <div className='col col-md-6'>
                       <h3> { type.value } TRANSACTIONS</h3>
                   </div> */}
                    <label className="mb-1">
                        <Translation id="type_of_transaction" defaultMessage="Type of transaction"></Translation>
                    </label>
                   <div className='col col-sm-4 mb-3'>
                       <Select
                           className='border-bottom border-secondary'
                           options={transactionOptions}
                           value={type}
                           onChange={(selectedOption) => {
                               setType({ ...selectedOption })
                           }}
                       />
                   </div>

               </div>
               <OrdersContainer type={type.value}></OrdersContainer>
           </div>
       </div>
    )
}

export default withRouter(injectIntl(connect()(TransactionsScreen)))
