import React from 'react'
import Translation from '../global/Translation'
import LocalizedLink from '../global/LocalizedLink'
import logo from '../../logo-horizontal.svg'
import { getIsLogged } from '../../store/login/reducers'
import { connect } from 'react-redux'
import Alerts from '../alerts/Alerts'
import { defineMessages } from 'react-intl'
// import mallDiscount from '../../assets/img/logos/mall-discount.svg'

defineMessages({
    TRADING_AVAILABLE_TEXT: {
        id: 'TRADING_AVAILABLE_TEXT',
        description: 'Trading available text',
        defaultMessage: 'Trading only available in Singapore area',
    }
})


const mapStateToProps = (state) => {
    return {
        isLogged: getIsLogged(state),
    }
}

class Footer extends React.Component {
    state = {
        expandBuySell: true,
        expandInformation: true,
        expandContact: true,
    }
    componentDidMount(){
        window.screen.width < 576 && this.setState({expandBuySell:false,expandInformation:false,expandContact:false})
        window.addEventListener('resize',()=>{
            if(window.screen.width < 576){
                 this.setState({expandBuySell:false,expandInformation:false,expandContact:false})
         } else{
                this.setState({expandBuySell:true,expandInformation:true,expandContact:true})
            }
        }
        )
    }
    render() {
        return (
            <div>
                <footer className="footer" id="footer">
                    <div className="container-fluid">
                        <div className="row pt-3 pb-3 px-4" id="footer-up">
                            <div className="col-12 col-md-6 col-lg-3">
                                <img alt="cards and collections logo" className="pb-3 filter-white img-fluid" src={logo} style={{ color: 'white', width: '260px' }} />
                                <p className="d-none d-md-block">Cards and Collections is a collectible marketplace, where you can manage your collection, sell and buy items. </p>
                                <p className="d-none d-md-block">Explore C&amp;C database and expand your collection</p>
                                <Alerts status="primary" alert={'TRADING_AVAILABLE_TEXT'} hideIcon={window.screen.width > 576? false: true}></Alerts>
                            </div>

                            <div className="col-12 col-md-6 col-lg-3">
                                <div
                                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between',cursor:'pointer' }}
                                    onClick={() => this.setState({ expandBuySell: !this.state.expandBuySell })}
                                >
                                    <Translation id="ADD_BUY_AND_SELL" defaultMessage="Add, buy and sell" />{' '}
                                    <span id="header-search-collapse__button" className="material-icons-outlined d-block d-md-none">
                                        {this.state.expandBuySell ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                                    </span>
                                </div>
                                <div style={{ display: this.state.expandBuySell ? 'block' : 'none' }}>
                                    <div className="text-white pb-3">
                                        <LocalizedLink routeId="ROUTE_STATIC_PAGE" params={{ view: 'add-collection' }}>
                                            <Translation id="ADD_TO_COLLECTION" defaultMessage="Add to collection" />
                                        </LocalizedLink>
                                    </div>
                                    <div className="text-white pb-3">
                                        <LocalizedLink routeId="ROUTE_STATIC_PAGE" params={{ view: 'want-to-sell' }}>
                                            <Translation id="WANT_TO_SELL" defaultMessage="Want to sell" />
                                        </LocalizedLink>
                                    </div>
                                    <div className="text-white pb-3">
                                        <LocalizedLink routeId="ROUTE_STATIC_PAGE" params={{ view: 'want-to-buy' }}>
                                            <Translation id="WANT_TO_BUY" defaultMessage="Want to buy" />
                                        </LocalizedLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 col-lg-3">
                                <div
                                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between',cursor:'pointer' }}
                                    onClick={() => this.setState({ expandInformation: !this.state.expandInformation })}
                                >
                                    <Translation id="INFORMATION" defaultMessage="Information" />
                                    <span id="header-search-collapse__button" className="material-icons-outlined d-block d-md-none">
                                        {this.state.expandInformation ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                                    </span>{' '}
                                </div>
                                <div style={{ display: this.state.expandInformation ? 'block' : 'none' }}>
                                    <div className="text-white pb-3">
                                        <LocalizedLink routeId={'ROUTE_STATIC_PAGE'} params={{ view: 'faq' }}>
                                            <Translation id="FAQS" defaultMessage="FAQs" />
                                        </LocalizedLink>
                                    </div>
                                    <div className="text-white pb-3">
                                        <LocalizedLink routeId={'ROUTE_STATIC_PAGE'} params={{ view: 'how-to-use' }}>
                                            <Translation id="HOW_TO_USE_CAC" defaultMessage="How to use C&C" />
                                        </LocalizedLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 col-lg-3">
                                <div
                                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between',cursor:'pointer' }}
                                    onClick={() => this.setState({ expandContact: !this.state.expandContact })}
                                >
                                    <Translation id="GET_IN_TOUCH" defaultMessage="Get in touch" />
                                    <span id="header-search-collapse__button" className="material-icons-outlined d-block d-md-none">
                                        {this.state.expandContact ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                                    </span>{' '}
                                </div>

                                <div style={{ display: this.state.expandContact ? 'block' : 'none' }}>
                                    <div className="text-white pb-3">
                                        <LocalizedLink routeId="ROUTE_SUGGESTIONS">
                                            <Translation id="SUGGESTION" defaultMessage="Suggestion" />
                                        </LocalizedLink>
                                    </div>

                                    <div className="text-white pb-3">
                                        <a href="mailto:support@cardsandcollections.com" target="_blank" rel="noreferrer">
                                            <Translation id="Contact" defaultMessage="Contact" />
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    <Translation id="follow_us" defaultMessage="Follow us" />
                                    <div className="pt-2">
                                        <a href="https://www.facebook.com/CardsandCollections/">
                                            <img src="/contact/facebook.svg" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row pb-2 px-4">
                            <div className="col-12 pb-md-3 col-lg-4">
                                <span className="col text-white">
                                    © Copyright {new Date().getFullYear()} C&C. <Translation id="ALL_RIGHTS_RESERVED" defaultMessage="All rights reserved" />{' '}
                                </span>
                            </div>
                            <div className="col-12 col-lg-8 text-sm-start text-lg-end text-white">
                                <LocalizedLink routeId="ROUTE_STATIC_PAGE" params={{ view: 'terms-of-service' }}>
                                    <u>
                                        <Translation id="TERMS_OF_SERVICE" />
                                    </u>
                                </LocalizedLink>
                                ,{' '}
                                <LocalizedLink routeId="ROUTE_STATIC_PAGE" params={{ view: 'private-policy' }}>
                                    <u>
                                        <Translation id="PRIVATE_POLICY" definedMessage="Private Policy" />
                                    </u>
                                </LocalizedLink>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}

export default connect(mapStateToProps)(Footer)
