import React from 'react'
import * as ui from '../../store/ui/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getIsLogged } from '../../store/login/reducers'
import LocalizedLink from '../../components/global/LocalizedLink'

const FAQScreen = () => {
    const isLogged = useSelector((state) => getIsLogged(state))
    const dispatch = useDispatch()
    const onShowUpForgotPassword = () => {
        dispatch(ui.showLoginModal())
    }

    return (
        <div className="mb-3 container-fluid">
            <h1>FAQS (Frequency answers and questions)</h1>
            <div className="accordion" id="accordionFAQ">
                <div className="mt-2">
                    <h2>User</h2>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                Is my username permanent?
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                Unfortunately, dude, it's unique and permanent. So, think about it as it will haunt you till the rest of your days.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Can I change my shipping address?
                            </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                Of course, as the great Chinese Sage Mr Unknown said, "We are leaves moved by the wind."
                                As long as the shipping address is in Singapore, it is possible to change it.
                                {
                                    isLogged && ( <div>the change,  <LocalizedLink routeId={'ROUTE_USER_PROFILE'} view={''}>click on</LocalizedLink>. </div>)
                                }
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Can I have multiple accounts?
                            </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                No. A single account that must be personal and non-transferable.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFour">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                I have forgotten my credentials to log in, what should I do?
                            </button>
                        </h2>
                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                Don't worry, not all of us have an elephant's memory.
                                It's simple, <a href="#"  onClick={onShowUpForgotPassword}>click on</a>  and you're done!
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFive">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                I live outside of Singapore, can I register?
                            </button>
                        </h2>
                        <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                Of course! Registering in Cards&Collections is completely free to manage your collections,
                                but if your intention is to carry out purchase-sale transactions,
                                things change and you need to be a resident of Singapore.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingSix">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                Can I buy/sell cards if I live outside of Singapore?
                            </button>
                        </h2>
                        <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                Sorry my friend. Cards&Collections is intended for users residing in Singapore.
                                But do not be in despair, we will open borders soon!
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingSeven">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                What are the different user types in Cards and Collections?
                            </button>
                        </h2>
                        <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                <h5 className="mt-3">Collector account</h5>
                                Registration is completely free, you will need to have a username, email and password.
                                There is no geographic restriction.
                                You will be able to manage your private collections through the user control panel.
                                <h5 className="mt-3">Seller-buyer account</h5>
                                It is totally free.
                                For Singapore resident users only.
                                A shipping address in Singapore and a valid bank account are required.
                                Said users will be able to publish both sale and purchase announcements in Cards&Collections , in addition to carrying out transactions with other users.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-3">
                    <h2>Transactions</h2>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingEight">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                What are the shipping costs?
                            </button>
                        </h2>
                        <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                Shipping costs are determined by the rates of the local parcel provider, according to the value of the items sold and their weight.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingNine">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                In a purchase transaction, who pays the shipping costs?
                            </button>
                        </h2>
                        <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                For Want to Sell Transaction, the buyer pays the shipping cost.
                                For Want to Buy Transactions, the seller pays the shipping costs.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTen">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                Can I choose certified shipping if the order is ordinary?
                            </button>
                        </h2>
                        <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                Yes, it is always possible to choose a higher shipping cost than the one chosen by default.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingEleven">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                What does my credit mean?
                            </button>
                        </h2>
                        <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                The amount of money that you can use to make transactions in Cards&Collections.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwelve">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                What is the purchase budget?
                            </button>
                        </h2>
                        <div id="collapseTwelve" className="accordion-collapse collapse" aria-labelledby="headingTwelve"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                It is an amount that reflects your budget to spend on items you want to buy.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThirteen">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                What happens if my purchase budget is zero?
                            </button>
                        </h2>
                        <div id="collapseThirteen" className="accordion-collapse collapse" aria-labelledby="headingThirteen"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                That other users of this platform will not be able to see your Want To Buy list.<br />
                                But your Want To Buy posting will not disappear, they will become visible again when your purchase budget has value again.<br />
                                It is a good option if you want to rest or take a vacation!<br />
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFourteen">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                What is our commission?
                            </button>
                        </h2>
                        <div id="collapseFourteen" className="accordion-collapse collapse" aria-labelledby="headingFourteen"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                5% of the value of the items sold, without taking shipping costs into account. Very cheap lah!
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFifteen">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                When does seller receive the money in a certified order?
                            </button>
                        </h2>
                        <div id="collapseFifteen" className="accordion-collapse collapse" aria-labelledby="headingFifteen"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                When the buyer confirms the arrival of the order
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingSixteen">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                                When does seller receive the money if it is a Want To Buy Transaction.
                            </button>
                        </h2>
                        <div id="collapseSixteen" className="accordion-collapse collapse" aria-labelledby="headingSixteen"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                When the buyer confirms the arrival of the order.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingSeventeen">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
                                Can the seller upload a real image to show the card condition?
                            </button>
                        </h2>
                        <div id="collapseSeventeen" className="accordion-collapse collapse" aria-labelledby="headingSeventeen"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                Of course. You can upload a back image as a front!
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-3">
                    <h2>Orders</h2>
                    <h3>How to pack an order</h3>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingEightteen">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseEightteen" aria-expanded="false" aria-controls="collapseEightteen">
                                Use the ideal envelope
                            </button>
                        </h2>
                        <div id="collapseEightteen" className="accordion-collapse collapse" aria-labelledby="headingEightteen"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                Use a mailing envelope that fits as close as possible to the size and thickness of the item.<br />
                                This will provide greater security and avoiding unexpected folds.<br />
                                Try not to use a window envelope and avoid bubble envelopes.<br />
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingNineteen">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseNineteen" aria-expanded="false" aria-controls="collapseNineteen">
                                Put the cards inside sleeves
                            </button>
                        </h2>
                        <div id="collapseNineteen" className="accordion-collapse collapse" aria-labelledby="headingNineteen"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                Put your cards in sleeves.<br />
                                It is recommended that you use perfect size, in addition to the tournament sleeve.<br />
                                If you don't use perfect size, insert a maximum of two cards per tournament sleeve.<br />
                                If you use perfect size, insert only one card per sleeve.<br />
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwenty">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwenty" aria-expanded="false" aria-controls="collapseTwenty">
                                Use a toploader (optional)
                            </button>
                        </h2>
                        <div id="collapseTwenty" className="accordion-collapse collapse" aria-labelledby="headingTwenty"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                Seal the cover of the toploader with a piece of paper on top and a bit of tape.
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwentyOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwentyOne" aria-expanded="false" aria-controls="collapseTwentyOne">
                                Place it on a postcard or piece of cardboard
                            </button>
                        </h2>
                        <div id="collapseTwentyOne" className="accordion-collapse collapse" aria-labelledby="headingTwentyOne"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                Find a postcard or a piece of cardboard (it is best if the material fits the size of your envelope).<br />
                                Place the sleeved card or toploader on the fitted postcard or cardboard and secure them with tape so they cannot move.<br />
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwentyTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwentyTwo" aria-expanded="false" aria-controls="collapseTwentyTwo">
                                Let's protect the cards
                            </button>
                        </h2>
                        <div id="collapseTwentyTwo" className="accordion-collapse collapse" aria-labelledby="headingTwentyTwo"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                We recommend to place another piece of postcard or cardboard and secure with tape.<br />
                                If it is impossible to use another postcard or if the package is over the weight limit for your shipping method,
                                use a cheap card on top of the exposed card (as long as the sleeve has room).<br />
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwentyThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwentyThree" aria-expanded="false" aria-controls="collapseTwentyThree">
                                We put the order in the envelope
                            </button>
                        </h2>
                        <div id="collapseTwentyThree" className="accordion-collapse collapse" aria-labelledby="headingTwentyThree"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                Place your postcard or fitted cardboard in the envelope. <br />
                                If you are using a large parcel, such as a cardboard box,
                                protect your cards with packing material to ensure that the cards are protected during transport
                                (you could even put the cards in an envelope first before placing them in the larger package to additional protection). <br />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-3">
                    <h2>Cards grading</h2>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwentyFour">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwentyFour" aria-expanded="false" aria-controls="collapseTwentyFour">
                                Pristine (number 10)
                            </button>
                        </h2>
                        <div id="collapseTwentyFour" className="accordion-collapse collapse" aria-labelledby="headingTwentyFour"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                A pristine card is a virtually perfect card.<br />
                                Attributes include four perfectly sharp corners, sharp focus and full original gloss.<br />
                                A pristine card must be free of staining of any kind, but an allowance may be made for a slight printing imperfection,
                                if it doesn´t impair the overall appeal of the card. The image must be centered on the card.<br />
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwentyFive">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwentyFive" aria-expanded="false" aria-controls="collapseTwentyFive">
                                Mint (number 9)
                            </button>
                        </h2>
                        <div id="collapseTwentyFive" className="accordion-collapse collapse" aria-labelledby="headingTwentyFive"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                A mint card is a superb condition card that exhibits only one of the following minor flaws: <br />
                                a very slight wax stain on reverse, a minor printing imperfection or slightly off-white borders. <br />
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwentySix">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwentySix" aria-expanded="false" aria-controls="collapseTwentySix">
                                Near mint-mint (number 8)
                            </button>
                        </h2>
                        <div id="collapseTwentySix" className="accordion-collapse collapse" aria-labelledby="headingTwentySix"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                A near mint-mint card is a super high-end card that appears Mint number 9 at first glance,<br />
                                but upon closer inspection, the card can exhibit the following:<br />
                                a very slight wax on reverse, slightest fraying at one or two corners,
                                a minor printing imperfection, and/or slightly off-white borders.<br />
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwentySeven">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwentySeven" aria-expanded="false" aria-controls="collapseTwentySeven">
                                Near mint (number 7)
                            </button>
                        </h2>
                        <div id="collapseTwentySeven" className="accordion-collapse collapse" aria-labelledby="headingTwentySeven"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                A Near mint is a card with just a slight surface wear visible upon close inspection.<br />
                                There may be slight fraying on some corners.<br />
                                Picture focus may be slightly out of register.<br />
                                A minor printing blemish is acceptable.<br />
                                Slight wax staining is acceptable on the back of the card only.<br />
                                Most of the original gloss is retained.<br />
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwentyEight">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwentyEight" aria-expanded="false" aria-controls="collapseTwentyEight">
                                Excellent-near mint (number 6)
                            </button>
                        </h2>
                        <div id="collapseTwentyEight" className="accordion-collapse collapse" aria-labelledby="headingTwentyEight"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                An excellent-near mint card may have visible surface wear or a printing defect which does not detract from its overall appeal.<br />
                                A very light scratch may be detected only upon close inspection.<br />
                                Corners may have slightly graduated fraying. Picture focus may be slightly out of register.<br />
                                Card may show some loss of original gloss, may have a minor wax stain on reverse,<br />
                                may exhibit very slight notching on edges and may also show some off whiteness on borders.<br />
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwentyNine">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwentyNine" aria-expanded="false" aria-controls="collapseTwentyNine">
                                Excellent (number 5)
                            </button>
                        </h2>
                        <div id="collapseTwentyNine" className="accordion-collapse collapse" aria-labelledby="headingTwentyNine"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                An excellent card, very minor rounding of corners is becoming evident.<br />
                                Surface wear or printing defects are more visible.<br />
                                There may be minor chipping on edges. Loss of original gloss will be more apparent.<br />
                                Focus of picture may be slightly out of register.<br />
                                Several light scratches may be visible upon close inspection, but do not detract from the appeal of the card.<br />
                                Card may show some off whiteness of borders.<br />
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThirty">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThirty" aria-expanded="false" aria-controls="collapseThirty">
                                Very good-excellent (number 4)
                            </button>
                        </h2>
                        <div id="collapseThirty" className="accordion-collapse collapse" aria-labelledby="headingThirty"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                A very good-excellent card´s corners may be slightly rounded.<br />
                                Surface wear is noticeable but modest.<br />
                                The card may have light scuffing or light scratches.<br />
                                Some original gloss will be retained.<br />
                                Borders may be slightly off white. A light crease may be visible.<br />
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThirtyOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThirtyOne" aria-expanded="false" aria-controls="collapseThirtyOne">
                                Very good (number 3)
                            </button>
                        </h2>
                        <div id="collapseThirtyOne" className="accordion-collapse collapse" aria-labelledby="headingThirtyOne"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                A very good card reveals some rounding of the corners, <br />
                                though not extreme. Some surface wear will be apparent, along with possible light scuffing or light scratches. <br />
                                Focus may be somewhat off register and edges may exhibit a noticeable wear. <br />
                                Much, but not all, of the card´s original gloss will be lost. <br />
                                Borders may be somewhat yellowed and/or discoloured. A crease may be visible. <br />
                                Printing defects are possible. Slight stain may show on obverse and wax staining on reverse may be prominent. <br />
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThirtyTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThirtyTwo" aria-expanded="false" aria-controls="collapseThirtyTwo">
                                Good (number 2)
                            </button>
                        </h2>
                        <div id="collapseThirtyTwo" className="accordion-collapse collapse" aria-labelledby="headingThirtyTwo"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                A good card´s corners show accelerated rounding and surface wear is starting to become obvious. <br />
                                A good card may have scratching, scuffing, light staining, or chipping of enamel on obverse. <br />
                                There may be several creases. Original gloss may be completely absent. Card may show considerable discoloration. <br />
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThirtyThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThirtyThree" aria-expanded="false" aria-controls="collapseThirtyThree">
                                Poor (number 1)
                            </button>
                        </h2>
                        <div id="collapseThirtyThree" className="accordion-collapse collapse" aria-labelledby="headingThirtyThree"
                             data-bs-parent="#accordionFAQ">
                            <div className="accordion-body">
                                A poor card´s corners will show extreme wear, possibly affecting framing of the picture. <br />
                                The surface of the card will show advanced stages of wear, including scuffing, scratching, pitting, chipping and staining. <br />
                                The picture will possibly be quite out of register and the borders may have become brown and dirty. <br />
                                The card may have one or more heavy creases. In order to archive a poor grade, a card must be fully intact. <br />
                                Even though the card may be heavily worn, it cannot achieve this grade if it is missing solid pieces of the card as a result of a major tear, etc. <br />
                                This would include damage such as the removal of the black layer of the card or an entire corner. <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FAQScreen