import * as api from '../api'
import { getToken, getUserId } from '../login/reducers'
import { getLanguage } from '../ui/reducers'
import { goBack } from 'connected-react-router'
import { fetchProductBuys, fetchProductSales } from '../products/actions'

export const fetchSaleItems = (page, pageSize, filters, orderBy) => (dispatch, getState) => {
    const userId = getUserId(getState())

    dispatch({ type: 'FETCH_SALE_ITEMS_REQUEST', page, pageSize, filters, orderBy, lastSellerId:  userId})

    return api
        .fetchSaleItems(getToken(getState()), page, pageSize, filters, orderBy)
        .then((response) => {
            return dispatch({
                type: 'FETCH_SALE_ITEMS_SUCCESS',
                saleItems: response.data.data,
                expansionsList: response.data.expansionsList,
                rarityList: response.data.rarityList,
                filters,
                orderBy,
                lastSellerId: userId,
            })
        })
        .catch((error) => {
            return dispatch({ type: 'FETCH_SALE_ITEMS_FAILURE', response: error.response })
        })
}

export const fetchSaleItemsFromUser = (userToFilter, page, pageSize, filters, orderBy) => (dispatch, getState) => {
    dispatch({ type: 'FETCH_SALE_ITEMS_FROM_USER_REQUEST', page, pageSize, filters, orderBy, lastSellerId: userToFilter})

    return api
        .fetchSaleItemsFromUser(getToken(getState()), userToFilter, page, pageSize, filters, orderBy)
        .then((response) => {
            return dispatch({
                type: 'FETCH_SALE_ITEMS_FROM_USER_SUCCESS',
                saleItems: response.data.data,
                expansionsList: response.data.expansionsList,
                rarityList: response.data.rarityList,
                filters,
                orderBy,
                lastSellerId: userToFilter,
            })
        })
        .catch((error) => {
            return dispatch({ type: 'FETCH_SALE_ITEMS_FROM_USER_FAILURE', response: error.response })
        })
}

export const fetchSaleItemById = (itemId) => (dispatch, getState) => {
    dispatch({ type: 'FETCH_SALE_ITEM_REQUEST', itemId })
    return api
        .fetchSaleItemById(getToken(getState()), itemId)
        .then((response) => {
            return dispatch({ type: 'FETCH_SALE_ITEM_SUCCESS', saleItem: response.data })
        })
        .catch((error) => {
            return dispatch({ type: 'FETCH_SALE_ITEM_FAILURE', response: error.response })
        })
}
export const fetchSaleItemByIdPublic = (itemId) => (dispatch, getState) => {
    dispatch({ type: 'FETCH_SALE_ITEM_REQUEST', itemId })
    return api
        .fetchSaleItemByIdPublic(getToken(getState()), itemId)
        .then((response) => {
            return dispatch({ type: 'FETCH_SALE_ITEM_SUCCESS', saleItem: response.data })
        })
        .catch((error) => {
            return dispatch({ type: 'FETCH_SALE_ITEM_FAILURE', response: error.response })
        })
}

export const fetchLastProductsAdded = (context) => (dispatch, getState) => {
    dispatch({ type: 'FETCH_LAST_PRODUCTS_REQUEST', context })
    return api
        .fetchLastProductsAdded(context, getToken(getState()))
        .then((response) => {
            return dispatch({ type: 'FETCH_LAST_PRODUCTS_SUCCESS', response: response.data, context })
        })
        .catch((error) => {
            return dispatch({ type: 'FETCH_LAST_PRODUCTS_FAILURE', response: error.response })
        })
}
export const addToSale = (saleItem, shouldGoBack) => (dispatch, getState) => {
    const locale = getLanguage(getState())
    dispatch({ type: 'ADD_TO_SALE_REQUEST', saleItem })
    return api
        .addToSale(getToken(getState()), saleItem)
        .then((response) => {
            dispatch(fetchProductSales(saleItem.card, locale))
            dispatch(fetchProductBuys(saleItem.card, locale))
            dispatch({ type: 'ADD_TO_SALE_SUCCESS', saleItem: response.data })

            if (shouldGoBack) {
                dispatch(goBack())
            }
            return response.data
        })
        .catch((error) => {
            let errors = null
            if (error.response && error.response.status === 422) {
                errors = error.response.data.errors
            }
            return dispatch({ type: 'ADD_TO_SALE_FAILURE', response: error.response, errors })
        })
}
export const editItemFromSale = (saleItem) => (dispatch, getState) => {
    dispatch({ type: 'EDIT_ITEM_FROM_SALE_REQUEST', saleItem })
    return api
        .editItemFromSale(getToken(getState()), saleItem)
        .then((response) => {
            dispatch(goBack())
            dispatch({ type: 'EDIT_ITEM_FROM_SALE_SUCCESS', saleItem: response.data })
            return response.data
        })
        .catch((error) => {
            let errors = null
            if (error.response && error.response.status === 422) {
                errors = error.response.data.errors
            }
            return dispatch({ type: 'EDIT_ITEM_FROM_SALE_FAILURE', response: error.response, errors })
        })
}
export const deleteItemFromSale = (itemId) => (dispatch, getState) => {
    const locale = getLanguage(getState())
    dispatch({ type: 'REMOVE_FROM_SALE_REQUEST', itemId })
    return api
        .deleteItemFromSale(getToken(getState()), itemId)
        .then((response) => {
            return dispatch({ type: 'REMOVE_FROM_SALE_SUCCESS' })
        })
        .catch((error) => {
            let errorCode = 'SOMETHING_WENT_WRONG'
            if (error.response.data.err) {
                errorCode = error.response.data.err
            }
            return dispatch({ type: 'REMOVE_FROM_SALE_FAILURE', errorCode })
        })
}

export const updateQtyItemFromSale = (itemId, qty) => (dispatch, getState) => {
    const locale = getLanguage(getState())
    dispatch({ type: 'UPDATE_QTY_ITEM_OF_SALE_REQUEST', itemId, qty })
    return api
        .updateQtyItemFromSale(getToken(getState()), itemId, qty)
        .then((response) => {
            return dispatch({ type: 'UPDATE_QTY_ITEM_OF_SALE_SUCCESS' })
        })
        .catch((error) => {
            return dispatch({ type: 'UPDATE_QTY_ITEM_OF_SALE_FAILURE', response: error.response })
        })
}

export const storeItemImagesFromSale = (saleItemId, images) => (dispatch, getState) => {
    const locale = getLanguage(getState())
    dispatch({ type: 'STORE_IMAGES_ITEM_FROM_SALE_REQUEST', payload: { saleItemId, images } })
    return api
        .storeItemImagesFromSale(getToken(getState()), saleItemId, images)
        .then((response) => {
            return dispatch({ type: 'STORE_IMAGES_ITEM_FROM_SALE_SUCCESS' })
        })
        .catch((error) => {
            let errors = null
            if (error.response && error.response.status === 422) {
                errors = error.response.data.errors
            }
            return dispatch({ type: 'STORE_IMAGES_ITEM_FROM_SALE_FAILURE', response: error.response, errors })
        })
}

export const updatePriceFromPriceProvider = (provider, operation, value,selectedMaterials) => (dispatch, getState) => {
    dispatch({ type: 'UPDATE_PRICE_FROM_PROVIDER_REQUEST', provider, operation, value,selectedMaterials })
    return api
        .updatePriceFromPriceProvider(getToken(getState()), provider, operation, value,selectedMaterials)
        .then((response) => {
            return dispatch({ type: 'UPDATE_PRICE_FROM_PROVIDER_SUCCESS', productsWithoutPrice: response.data })
        })
        .catch((error) => {
            let errorCode = 'SOMETHING_WENT_WRONG'
            if (error.response.data.err) {
                errorCode = error.response.data.err
            }
            return dispatch({ type: 'UPDATE_PRICE_FROM_PROVIDER_FAILURE', errorCode })
        })
}
