import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import Translation from '../../../global/Translation'
import { setNestedObjectValues, useFormik } from 'formik'
import Button from '../../../global/Button'
import * as Yup from 'yup'
import { getShippingAddress, getShippingAddressErrors } from '../../../../store/login/reducers'
import { updateShippingInformation } from '../../../../store/login/actions'

const ShippingInformation = (props) => {

    const dispatch = useDispatch()

    const shippingAddressErrors = useSelector(state => getShippingAddressErrors(state))
    const shippingAddress = useSelector(state => getShippingAddress(state))

    const initialValues = {
        shippingName: shippingAddress && shippingAddress.full_name,
        shippingCountry: 1,
        shippingAddress: shippingAddress && shippingAddress.address,
        shippingZipCode: shippingAddress && shippingAddress.zip_code,
        shippingLocation: shippingAddress && shippingAddress.location
    }

    const validationSchema = Yup.object({
        shippingName: Yup.string().required(props.intl.formatMessage({ id: 'REQUIRED' })).nullable(),
        shippingCountry: Yup.string().required(props.intl.formatMessage({ id: 'REQUIRED' })).nullable(),
        shippingAddress: Yup.string().required(props.intl.formatMessage({ id: 'REQUIRED' })).nullable(),
        shippingZipCode: Yup.string().required(props.intl.formatMessage({ id: 'REQUIRED' })).nullable(),
        shippingLocation: Yup.string().required(props.intl.formatMessage({ id: 'REQUIRED' })).nullable()
    })

    const onSubmit = (values, action) => {
        dispatch(updateShippingInformation(values))
        action.resetForm({ values })
    }

    const handleClickSubmit = event => {
        formik.submitForm()
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    })

    useEffect(() => {
        if (shippingAddressErrors) {
            formik.setTouched(setNestedObjectValues(shippingAddressErrors, true), false)
                .then(() => {
                    formik.setErrors(shippingAddressErrors)
                })
        }
    }, [shippingAddressErrors, formik])

    return (
        <React.Fragment>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12'>
                        <form className='form'>
                            <div className=''>
                                <h3>
                                    <Translation id='shipping_address' defaultMessage='Shipping Address' />
                                </h3>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='row mb-3'>
                                            <div className='col-md-6'>
                                                <label htmlFor='shippingName' className='form-label'>
                                                    <Translation id='full_name' defaultMessage='Full Name' /> *
                                                </label>
                                                <input
                                                    className='form-control mb-3'
                                                    type='text'
                                                    id='shippingName'
                                                    name='shippingName'
                                                    placeholder={props.intl.formatMessage({ id: 'full_name' })}
                                                    value={formik.values.shippingName}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.shippingName && formik.errors.shippingName ? (
                                                    <div style={{ color: 'red' }}>{formik.errors.shippingName}</div>
                                                ) : null
                                                }
                                            </div>
                                            <div className='col-md-6'>
                                                <label htmlFor='shippingCountry' className='form-label'>
                                                    <Translation id='country' defaultMessage='Country' /> *
                                                </label>
                                                <select className='form-select mb-3'
                                                        name='shippingCountry'
                                                        disabled
                                                        value={formik.values.registerCountry}
                                                >
                                                    <option value='SG'>Singapore</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='row mb-3'>
                                            <div className='col-md-6'>
                                                <label htmlFor='shippingAddress' className='form-label'>
                                                    <Translation id='address' defaultMessage='Address' /> *
                                                </label>
                                                <input
                                                    className='form-control mb-3'
                                                    type='text'
                                                    id='shippingAddress'
                                                    name='shippingAddress'
                                                    placeholder={props.intl.formatMessage({ id: 'address' })}
                                                    value={formik.values.shippingAddress}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.shippingAddress && formik.errors.shippingAddress ? (
                                                    <div style={{ color: 'red' }}>{formik.errors.shippingAddress}</div>
                                                ) : null
                                                }
                                            </div>
                                            <div className='col-md-6'>
                                                <label htmlFor='registerZipCode' className='form-label'>
                                                    <Translation id='zipcode' defaultMessage='Zipcode' /> *
                                                </label>
                                                <input
                                                    className='form-control mb-3'
                                                    type='text'
                                                    id='shippingZipCode'
                                                    name='shippingZipCode'
                                                    placeholder={props.intl.formatMessage({ id: 'zipcode' })}
                                                    value={formik.values.shippingZipCode}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.shippingZipCode && formik.errors.shippingZipCode ? (
                                                    <div style={{ color: 'red' }}>{formik.errors.shippingZipCode}</div>
                                                ) : null
                                                }
                                            </div>
                                        </div>
                                        <div className='row mb-3'>
                                            <div className='col-md-6'>
                                                <label htmlFor='shippingLocation' className='form-label'>
                                                    <Translation id='city' defaultMessage='City' /> *
                                                </label>
                                                <input
                                                    className='form-control mb-3'
                                                    type='text'
                                                    id='shippingLocation'
                                                    name='shippingLocation'
                                                    placeholder={props.intl.formatMessage({ id: 'city' })}
                                                    value={formik.values.shippingLocation}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.shippingLocation && formik.errors.shippingLocation ? (
                                                    <div style={{ color: 'red' }}>{formik.errors.shippingLocation}</div>
                                                ) : null
                                                }
                                            </div>
                                        </div>
                                        <div className='row mb-3'>
                                            <div className='col-'>
                                                <Button type='submit' disabled={!formik.dirty || !formik.isValid}
                                                        value='Save'
                                                        onClick={handleClickSubmit}>
                                                    <Translation id='Save' defaultMessage='Save' />
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default withRouter(injectIntl(connect()(ShippingInformation)))
