import { toggleMenu, showLoginModal } from './../../store/ui/actions';
import { push } from 'connected-react-router';
import { getCredit, getBuyCredit, getUserId, getIsLogged } from './../../store/login/reducers'
import { connect } from 'react-redux'
import Menu from './Menu'
import { showMenuSelector } from '../../store/ui/reducers'
import { getUserType } from '../../store/login/reducers'
import { getAllUnreadMessages } from '../../store/chats/reducers'
import * as ui from '../../store/ui/reducers'

const mapStateToProps = (state) => {
    return {
        showMenu: showMenuSelector(state),
        userType: getUserType(state),
        credit: getCredit(state),
        buyCredit: getBuyCredit(state),
        unreadMessagesCount: getAllUnreadMessages(state),
        currentUrl: ui.getCurrentUrl(state),
        userId: getUserId(state),
        isLogged: getIsLogged(state)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (url) => {
            dispatch(push(url))
        },
        onToggleMenu: (active) => {
            dispatch(toggleMenu(active))
        },
        onShowUpLogin: () => {
            dispatch(showLoginModal())
        },
    }
}

const MenuContainer = connect(mapStateToProps, mapDispatchToProps)(Menu)

export default MenuContainer
