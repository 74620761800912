import { createConfirmation } from 'react-confirm'
import ConfirmDialog from './ConfirmDialog'

// create confirm function
const confirmFunction = createConfirmation(ConfirmDialog)

const confirm = (confirmation, options = {}) => {
    return confirmFunction({ confirmation, options })
}

export default confirm