import { connect } from 'react-redux'
import { getUserName } from '../../store/login/reducers'
import { push } from 'connected-react-router'
import Notifications from './Notifications'
import { fetchMyNotifications } from '../../store/notifications/actions'
import { getNotifications } from '../../store/notifications/reducers'

const mapStateToProps = (state, { match }) => {
    return {
        userName: getUserName(state),
        notifications: getNotifications(state),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        redirect: (url) => Promise.resolve(dispatch(push(url))),
        fetchMyNotifications: () => dispatch(fetchMyNotifications()),
    }
}


const NotificationsContainer = connect(mapStateToProps, mapDispatchToProps)(Notifications)

export default NotificationsContainer
