import React from 'react'
import { setCollectableContext } from '../../store/products/actions'
import { customHitStats, MySortingSelector, ProductCategorySelector } from '../../Results'
import { toggleMenu } from '../../store/ui/actions'
import * as ui from '../../store/ui/reducers'
import { showMenuSelector } from '../../store/ui/reducers'
import { withRouter } from 'react-router-dom'
import { push } from 'connected-react-router'
import Footer from '../footer/Footer'
import HeaderContainer from '../header/HeaderContainer'
import MenuContainer from '../menu/MenuContainer'
import { connect } from 'react-redux'
import { getIsLogged } from '../../store/login/reducers'
import { removeToastNotification } from '../../store/toast-notifications/actions'
import { injectIntl } from 'react-intl'
import logo from '../../logo.svg'
import logoHorizontal from '../../logo-horizontal.svg'
import { HitsStats, SearchkitManager, SearchkitProvider } from 'searchkit'
import SubHeaderContainer from '../subheader/SubheaderContainer'
import BreadCrumbs from '../menu/BreadCrumbs'
import { setAdvancedSearchDefaultFilters } from '../../screens/AdvancedSearchScreen'
import { getCurrentCollectableContext } from '../../store/products/reducers'
import {
    COLLECTABLE_CONTEXT_MTG,
    COLLECTABLE_CONTEXT_OPTIONS,
    setCollectableContextFilter
} from '../../store/products/CollectableContext'
import store from '../../index'

const selectCollectableContextStyles = {
    control: (styles) => ({ ...styles }),
    option: (styles) => {
        return {
            ...styles,
            color: 'black'
        }
    }
}
const mapStateToProps = (state, { match }) => {
    return {
        // showingAnyModal: ui.showingAnyModal(state),
        isLogged: getIsLogged(state),
        toastNotifications: state.toastNotifications,
        currentUrl: ui.getCurrentUrl(state),
        showMenu: showMenuSelector(state),
        collectableContext: getCurrentCollectableContext(state)
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        removeToastNotification: (id) => dispatch(removeToastNotification(id)),
        onSubmit: (url) => {
            dispatch(push(url))
        },
        onToggleMenu: (active) => {
            dispatch(toggleMenu(active))
        },
        setCollectableContext: (context) => {
            dispatch(setCollectableContext(context))
        }
    }
}

const getSk = (collectableContext, token) => {
    const catalogUri = '/api/catalog/'
    const httpHeaders = {
        CollectableContext: collectableContext ?? COLLECTABLE_CONTEXT_MTG
    }

    if (token) {
        httpHeaders['Authorization'] = `Bearer ${token}`
    }

    let sk = new SearchkitManager(process.env.REACT_APP_API_HOST + catalogUri, {
        searchUrlPath: '',
        timeout: 60000,
        httpHeaders
    })
    setCollectableContextFilter(sk, collectableContext)

    // sk.addDefaultQuery(query => {
    //     return query.addFilter('collectableContext', BoolMust(TermsQuery('collectableContext.raw', [collectableContext, COLLECTABLE_CONTEXT_ACCESSORY])))
    // })
    // Traducciones de cadenas de SearchKit
    // sk.translateFunction = (key) => {
    //     let translations = {
    //         'pagination.previous': <Translation id="previous" defaultMessage="Previous" />,
    //         'pagination.next': <Translation id="next" defaultMessage="Next" />,
    //         'facets.view_more': <Translation id="view_more" defaultMessage="View more" />,
    //         'facets.view_less': <Translation id="view_less" defaultMessage="View less" />,
    //         'facets.view_all': <Translation id="view_all" defaultMessage="View all" />,
    //         'reset.clear_all': <Translation id="clear_all" defaultMessage="Clear all" />,
    //     }
    //     return translations[key]
    // }
    return sk
}

const setDefaultFiltersDependOfCurrentPath = (location, sk) => {
    if (location.pathname.includes('advanced-search')) {
        setAdvancedSearchDefaultFilters(sk)
    }
}

class Layout extends React.Component {
    constructor(props) {
        super(props)
        this.state = { error: null, errorInfo: null, showMaintenanceText: null, sk: null }
    }

    setSk = (callback) => {
        this.setState(
            {
                sk: getSk(this.props.collectableContext, this.props.token)
            },
            () => {
                if (callback) {
                    callback()
                }
            }
        )
    }

    componentDidMount() {

        this.setSk(() => {
            setDefaultFiltersDependOfCurrentPath(this.props.location, this.state.sk)
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname
            || this.props.collectableContext !== prevProps.collectableContext) {
            // tener en cuenta que al cambiar de ruta, se resetea todos los componentes
            // reseteamos el sk config y buscamos de nuevo, para que coja las búsquedas de rutas
            // cuando el layout no se desmonta
            this.setState({ sk: null }, () => {
                // aparentemente no es necesario realizar el runInitialSearch, porque hace doble búsqueda
                this.setSk(() => {

                    if (this.props.collectableContext !== prevProps.collectableContext) {
                        this.state.sk.query
                            .getSelectedFilters()
                            .filter(it => it.name === 'productCategory')
                            .map(it => it.remove())
                        this.state.sk.performSearch()
                    }

                    setDefaultFiltersDependOfCurrentPath(this.props.location, this.state.sk)
                    // this.state.sk.runInitialSearch()
                })
            })
        }
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        if (process.env.NODE_ENV === 'production') {
            // Raven.captureException(error, { extra: errorInfo })
        }

        // You can also log error messages to an error reporting service here
    }

    render() {
        // if (this.props.showingAnyModal) {
        //     document.body.classList.add('noscroll')
        // } else {
        //     document.body.classList.remove('noscroll')
        // }
        let routeCatalog = this.props.intl.formatMessage({ id: 'ROUTE_CATALOG' })
        let isInCatalog = this.props.currentUrl.pathname == routeCatalog
        let routeHome = this.props.intl.formatMessage({ id: 'ROUTE_HOME' })
        let isInHome = this.props.currentUrl.pathname == routeHome || this.props.currentUrl.pathname == routeHome+'/'
        if (!this.state.sk) {
            return null
        }
        if (this.state.errorInfo) {
            return (
                <div>
                    <HeaderContainer />

                    <div className='container' style={{ minHeight: 'calc(100vh - 323px)' }}>
                        <div className='row'>
                            <div className='col'>
                                <h2>Something went wrong</h2>
                                {/* <p onClick={() => Raven.lastEventId() && Raven.showReportDialog()}>Our team has been notified, but click here fill out a report.</p> */}
                                {process.env.NODE_ENV !== 'production' && (
                                    <details style={{ whiteSpace: 'pre-wrap' }}>
                                        {this.state.error && this.state.error.toString()}
                                        <br />
                                        {this.state.errorInfo.componentStack}
                                    </details>
                                )}
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>
            )
        }
        let selectContext = (
            <select
                style={{ background: 'transparent', border: 'none', outline: 0 }}
                value={this.props.collectableContext}
                onChange={(selectedOption) => {
                    this.props.setCollectableContext(selectedOption.target.value)
                }}
            >
                {COLLECTABLE_CONTEXT_OPTIONS.map((option) => {
                    return (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    )
                })}
            </select>
        )
        // Normally, just render children
        return (
            <div>
                <SearchkitProvider searchkit={this.state.sk}>
                    <div id='wrapper-full'>
                        <div id='wrapper-left'>
                            <div id='left-sidebar'>
                                <div style={{cursor: 'pointer'}} onClick={(e) => {
                                    e.preventDefault()
                                    window.location.href = '/'
                                }}>
                                    <img src={logo} className='d-none d-md-block' alt='logo'></img>
                                    <img src={logoHorizontal} className='d-block d-md-none' alt='logo'></img>
                                </div>
                                <MenuContainer />
                            </div>
                        </div>
                        <div id='wrapper-right'>
                            <div id='wrapper-header'>
                                <HeaderContainer />
                            </div>
                            <div id="wrapper-contain" style={{ marginTop: '112px' }}>
                                {!isInHome && (
                                    <div className="container-fluid g-0 m-0 p-0 mb-3" style={{ background: 'transparent' }}>
                                        <div
                                            className="row g-0"
                                            style={{
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                background: 'white',
                                                borderRadius: '8px',
                                                padding: '8px 0',
                                                minHeight: '56px',
                                            }}
                                        >
                                            <div className="col-12 col-md-6 ps-2">
                                                {!isInCatalog && selectContext}

                                                {isInCatalog && (
                                                    <div className="d-flex align-items-center flex-row gap-2 flex-wrap filters-bar">
                                                        {selectContext}
                                                        <MySortingSelector intl={this.props.intl}></MySortingSelector>
                                                        <ProductCategorySelector intl={this.props.intl}></ProductCategorySelector>
                                                        <span className="d-none d-md-block" style={{ marginLeft: '16px' }}>
                                                            <HitsStats component={customHitStats} /> Results
                                                        </span>
                                                    </div>
                                                )}
                                                <div style={{ paddingLeft: '4px' }}>
                                                    <BreadCrumbs />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <SubHeaderContainer />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {this.props.children}
                            </div>
                            <Footer />
                        </div>
                    </div>
                </SearchkitProvider>
            </div>
        )
    }
}

Layout = withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(Layout)))

export default Layout
