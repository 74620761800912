import * as api from '../api'

export const fetchDropOffAvailableStores = () => (dispatch, getState) => {
    dispatch({ type: 'FETCH_DROP_OFF_AVAILABLE_STORES_REQUEST'})

    return api
        .fetchDropOffAvailableStores()
        .then((response) => {
            return dispatch({
                type: 'FETCH_DROP_OFF_AVAILABLE_STORES_SUCCESS',
                dropOffAvailableStores: response.data.stores
            })
        })
        .catch((error) => {
            return dispatch({ type: 'FETCH_DROP_OFF_AVAILABLE_STORES_FAILURE', response: error.response })
        })
}
