import { RenderSaleItemMobile } from '../sale/list/RenderSaleItemMobile'
import { defineMessages, injectIntl } from 'react-intl'
import React, { useState } from 'react'
import Spinner from '../global/Spinner'
import Translation from '../global/Translation'
import Modal from '../global/Modal'
import Button from '../global/Button'
import DisplayPrice from '../product/DisplayPrice'
import Alerts from '../alerts/Alerts'
import { PublicProfileLink } from '../global/PublicProfileLink'
import { Votes } from '../../screens/PublicProfileScreen'
import paynow from '../../assets/img/paynow.jpg'
import { ShippingAddress } from '../address/ShippingAddress'
import { PickupAddress } from '../address/PickupAddress'
import { FileInput } from '../global/FileInput'
import { OrderDropOffFile } from './OrderDropOffFile'
import { useFormik } from 'formik'
import { updateUserDropOffOptions } from '../../store/login/actions'
import { selectMultiOptionExtractValue } from '../shared/form/select-input'
import * as Yup from 'yup'

defineMessages({
    'buyer.lost': {
        id: 'buyer.lost',
        description: 'buyer.lost',
        defaultMessage: 'Order is lost, we are sorry, contact with the seller'
    },
    'seller.lost': {
        id: 'seller.lost',
        description: 'seller.lost',
        defaultMessage: 'Order is lost, we are sorry.'
    },
    'buyer.confirm': {
        id: 'buyer.confirm',
        description: 'buyer.confirm',
        defaultMessage: 'Pending buyer confirmation'
    },
    'buyer.recibir': {
        id: 'buyer.recibir',
        description: 'buyer.recibir',
        defaultMessage: 'Pending until buyer confirm arrival'
    },
    'buyer.pay': {
        id: 'buyer.pay',
        description: 'buyer.pay',
        defaultMessage: 'Pending until buyer completes payment'
    },
    'buyer.vote': {
        id: 'buyer.vote',
        description: 'buyer.vote',
        defaultMessage: 'Wait buyer vote'
    },
    'seller.confirm': {
        id: 'seller.confirm',
        description: 'seller.confirm',
        defaultMessage: 'Waiting for the seller to confirm the shipment'
    },
    'seller.send': {
        id: 'seller.send',
        description: 'seller.send',
        defaultMessage: 'Seller did not send the order'
    },
    'seller.no_confirmada': {
        id: 'seller.no_confirmada',
        description: 'seller.no_confirmada',
        defaultMessage: 'Seller wants to cancel the order'
    },
    'buyer.cancel': {
        id: 'buyer.cancel',
        description: 'buyer.cancel',
        defaultMessage: 'Wait until buyer confirm order cancellation'
    },
    'transaction.unpaid': {
        id: 'transaction.unpaid',
        description: 'transaction.unpaid',
        defaultMessage: 'Unpaid'
    },
    'transaction.pay': {
        id: 'transaction.pay',
        description: 'transaction.pay',
        defaultMessage: 'Pay'
    },
    'transaction.paid': {
        id: 'transaction.paid',
        description: 'transaction.paid',
        defaultMessage: 'Paid'
    },
    'transaction.pending_confirm': {
        id: 'transaction.pending_confirm',
        description: 'transaction.pending_confirm',
        defaultMessage: 'Pending Confirm'
    },
    'transaction.not_relist': {
        id: 'transaction.not_relist',
        description: 'transaction.not_relist',
        defaultMessage: 'No relist'
    },
    'transaction.cancel': {
        id: 'transaction.cancel',
        description: 'transaction.cancel',
        defaultMessage: 'Accept'
    },

    'transaction.not_cancel': {
        id: 'transaction.not_cancel',
        description: 'transaction.not_cancel',
        defaultMessage: 'Reject'
    },
    'transaction.lost': {
        id: 'transaction.lost',
        description: 'transaction.lost',
        defaultMessage: 'Lost'
    },
    'transaction.cancel_finished': {
        id: 'transaction.cancel_finished',
        description: 'transaction.cancel_finished',
        defaultMessage: 'Cancelled'
    },
    'transaction.finished': {
        id: 'transaction.finished',
        description: 'transaction.finished',
        defaultMessage: 'The order has been completed'
    },
    'transaction.cancelled': {
        id: 'transaction.cancelled',
        description: 'transaction.cancelled',
        defaultMessage: 'Cancellation requested'
    },
    'transaction.expired': {
        id: 'transaction.expired',
        description: 'transaction.expired',
        defaultMessage: 'Expired'
    },
    'transaction.vote': {
        id: 'transaction.vote',
        description: 'transaction.vote',
        defaultMessage: 'Vote'
    },
    'transaction.pick_up_ready': {
        id: 'transaction.pick_up_ready',
        description: 'transaction.pick_up_ready',
        defaultMessage: 'Pick up ready'
    },
    'transaction.received': {
        id: 'transaction.received',
        description: 'transaction.received',
        defaultMessage: 'Received'
    },
    'transaction.confirm': {
        id: 'transaction.confirm',
        description: 'transaction.confirm',
        defaultMessage: 'Confirm'
    },
    'transaction.not_confirm': {
        id: 'transaction.not_confirm',
        description: 'transaction.not_confirm',
        defaultMessage: 'Reject'
    },
    'transaction.confirmed': {
        id: 'transaction.confirmed',
        description: 'transaction.confirmed',
        defaultMessage: 'Confirmed'
    },
    'transaction.send': {
        id: 'transaction.send',
        description: 'transaction.send',
        defaultMessage: 'Send'
    },
    'transaction.relist': {
        id: 'transaction.relist',
        description: 'transaction.relist',
        defaultMessage: 'List again'
    },
    'transaction.no_relist': {
        id: 'transaction.no_relist',
        description: 'transaction.no_relist',
        defaultMessage: 'No list'
    },
    'transaction.drop_off_ready': {
        id: 'transaction.drop_off_ready',
        description: 'transaction.drop_off_ready',
        defaultMessage: 'Items dropped in store'
    },
    'order.buying_info_message': {
        id: 'order.buying_info_message',
        description: 'order.buying_info_message',
        defaultMessage: 'You are buying those items from '
    },
    'order.selling_info_message': {
        id: 'order.selling_info_message',
        description: 'order.selling_info_message',
        defaultMessage: 'You are selling those items to '
    },
    'order.status.pending_confirm': {
        id: 'order.status.pending_confirm',
        description: 'order.status.pending_confirm',
        defaultMessage: 'Pending confirm'
    },
    'order.status.confirmed': {
        id: 'order.status.confirmed',
        description: 'order.status.confirmed',
        defaultMessage: 'Confirmed'
    },
    'order.status.not_confirmed': {
        id: 'order.status.not_confirmed',
        description: 'order.status.not_confirmed',
        defaultMessage: 'Not confirmed'
    },
    'order.status.cancelled': {
        id: 'order.status.cancelled',
        description: 'order.status.cancelled',
        defaultMessage: 'Cancellation requested'
    },
    'order.status.cancel_finished': {
        id: 'order.status.cancel_finished',
        description: 'order.status.cancel_finished',
        defaultMessage: 'Cancelled'
    },
    'order.status.send': {
        id: 'order.status.send',
        description: 'order.status.send',
        defaultMessage: 'Shipped'
    },
    'order.status.received': {
        id: 'order.status.received',
        description: 'order.status.received',
        defaultMessage: 'Delivered'
    },
    'order.status.lost': {
        id: 'order.status.lost',
        description: 'order.status.lost',
        defaultMessage: 'Lost'
    },
    'order.status.finished': {
        id: 'order.status.finished',
        description: 'order.status.finished',
        defaultMessage: 'Completed'
    },
    'order.status.unpaid': {
        id: 'order.status.unpaid',
        description: 'order.status.unpaid',
        defaultMessage: 'Unpaid'
    },
    'order.status.expired': {
        id: 'order.status.expired',
        description: 'order.status.expired',
        defaultMessage: 'Expired'
    },
    'order.status.pick_up_ready': {
        id: 'order.status.pick_up_ready',
        description: 'order.status.pick_up_ready',
        defaultMessage: 'Pick up ready'
    },
    'order.status.drop_off_ready': {
        id: 'order.status.drop_off_ready',
        description: 'order.status.drop_off_ready',
        defaultMessage: 'Drop off ready'
    },
    'order.instruction.certified.seller': {
        id: 'order.instruction.certified.seller',
        description: 'order.instruction.certified.seller',
        defaultMessage: 'Just send the package via certified mail. Once the buyer confirms they received it, you\'ll get paid.'
    },
    'order.instruction.pickup_between_users.seller': {
        id: 'order.instruction.pickup_between_users.seller',
        description: 'order.instruction.pickup_between_users.seller',
        defaultMessage: 'Drop off the products at the store, and you\'ll receive the payment once you confirm they\'ve been dropped off.'
    },
})

const AlertShippingMethodInstruction = ({ shippingMethodType, isSeller, isBuyer }) => {
    const shippingMethodSellerInstructions = {
        normal: null,
        certified: <Alerts status='info' alert='order.instruction.certified.seller' />,
        pickup: null,
        pickup_between_users: <Alerts status='info' alert='order.instruction.pickup_between_users.seller' />,
    }

    if (isSeller) {
        return shippingMethodSellerInstructions[shippingMethodType?.toLowerCase()] ?? null
    }

    return null
}

export const getCheckoutUrlByCheckoutId = (checkoutId) => {
    return `${window.location.origin}/checkout-summary/${checkoutId}`
}

const OrderTitle = ({
                        currentUserId,
                        buyerId,
                        buyerName,
                        buyerNameSlug,
                        sellerId,
                        sellerName,
                        sellerNameSlug,
                        orderId
                    }) => {
    const buyerTitle = (
        <h5>
            <Translation id='order.buying_info_message' /> <PublicProfileLink userNameSlug={sellerNameSlug}
                                                                              label={sellerName} /> {` (#${orderId})`}
        </h5>
    )
    const sellerTitle = (
        <h5>
            <Translation id='order.selling_info_message' /> <PublicProfileLink userNameSlug={buyerNameSlug}
                                                                               label={buyerName} /> {` (#${orderId})`}
        </h5>
    )
    if (currentUserId === sellerId) {
        return sellerTitle
    }
    return buyerTitle
}

export const BuyerPayModal = ({ order, credit, onClose, payMyPendingCredit }) => {
    const downloadQRCode = (e) => {
        e.preventDefault()

        let anchor = document.createElement('a')
        anchor.href = paynow
        anchor.download = `Paynow Cards And Collections.jpg`
        document.body.appendChild(anchor)
        anchor.click()
        document.body.removeChild(anchor)
    }

    return (
        <Modal title={<Translation id='add_credit_by_paynow' defaultMessage='Add Credit by Paynow'></Translation>}
               onClose={onClose}>
            <div className='container'>
                <p>Please make the payment and remember to put those details:</p>
                <ul>
                    <li>
                        Phone number: <strong>96 21 34 39</strong>
                    </li>
                    <li>
                        Concept: <strong>{order.buyer_name}</strong>
                    </li>
                    <li>
                        Quantity:{' '}
                        <strong>
                            <DisplayPrice price={Math.abs(credit)}></DisplayPrice>
                        </strong>
                    </li>
                </ul>
                <Button onClick={() => payMyPendingCredit(getCheckoutUrlByCheckoutId(order.id))}>Pay</Button>
                {/* <DownloadPaynowImage /> */}
            </div>
        </Modal>
    )
}

export const DropOffFileModal = ({
                                     isSending,
                                     intl,
                                     errors,
                                     uploadFile,
                                     onClose,
                                 }) => {

    const [file, setFile] = useState()

    return <Modal
        title={<Translation id='drop_off_file' defaultMessage='Drop off file'></Translation>}
        onClose={onClose}
    >
        <form
            onSubmit={(event) => {
                event.preventDefault()
                uploadFile(file)
            }}
            className='form-inline'
        >
            <div className='row form-inline'>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                    }}
                >
                    <FileInput
                        id='dropOffFile'
                        name='dropOffFile'
                        accept='image/png, image/jpeg'
                        label={intl.formatMessage({ id: 'drop_off_file' })}
                        placeholder={intl.formatMessage({ id: 'drop_off_file' })}
                        onFileSelect={(newFile) => setFile(newFile)}
                        onFileDelete={() => setFile(null)}
                        errors={errors}
                    />
                </div>
                <div className='row'>
                    <div className='col'>
                        <input
                            className='bt mt-2 mr-2 ml-2'
                            disabled={isSending}
                            type='submit'
                            value={intl.formatMessage({ id: 'SAVE' })}
                        />
                    </div>
                </div>
            </div>
        </form>
    </Modal>
}

export const PartialRefundModal = ({
                                       order,
                                       isSending,
                                       intl,
                                       submitPartialRefund,
                                       onClose,
                                   }) => {

    const initialValues = {
        refundImport: null,
    }

    const onSubmit = (values, action) => {
        submitPartialRefund(values.refundImport)
    }

    const validationSchema = Yup.object({
        refundImport: Yup.number()
            .min(0.01, intl.formatMessage({ id: 'FORM_ERROR_MIN' }, { min: '0,01' }))
            .max(order.max_partial_refund_amount_allowed, intl.formatMessage({ id: 'FORM_ERROR_MAX' }, { max: order.max_partial_refund_amount_allowed }))
            .required(intl.formatMessage({id:'REQUIRED'}))
            .test(
                "max-decimals",
                "Only up to 2 decimal places are allowed",
                (value) => {
                    return /^(\d+(\.\d{1,2})?)?$/.test(value);
                }
            )
    })

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
    })

    const handleClickSubmit = () => {
        formik.submitForm()
    }

    return <Modal
        title={<Translation id="refund" defaultMessage="Refund"></Translation>}
        onClose={onClose}
    >
        <form className="form-inline"
        >
            <div className="row form-inline">
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    <label htmlFor="refund_import" style={{ width: '240px' }}>
                        <Translation id="refund_import" defaultMessage="Refund import"></Translation>
                    </label>

                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <input
                            type="number"
                            required
                            id="refundImport"
                            name="refundImport"
                            placeholder={`Max: ${order.max_partial_refund_amount_allowed}`}
                            autoComplete="off"
                            value={formik.values.refundImport}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.refundImport && formik.errors.refundImport ? <div style={{ color: 'red' }}>{formik.errors.refundImport}</div> : null}
                    </div>

                </div>

                <div className="row">
                    <div className="col">
                        <Button
                            className="bt mt-2 mr-2 ml-2"
                            type='submit' disabled={!formik.dirty || !formik.isValid}
                                onClick={handleClickSubmit}>
                            {intl.formatMessage({ id: 'REFUND' })}
                        </Button>
                    </div>
                </div>

            </div>
        </form>
    </Modal>
}

class Order extends React.Component {
    componentDidMount = () => {
        this.props.fetchOrderById(this.props.id)
    }

    constructor(props) {
        super(props)
        this.state = {
            needMessage: false,
            needPayment: false,
            messageEntered: null,
            needVotes: false,
            votesEntered: null,
            needTrackingNumber: false,
            trackingNumberEntered: null,
            needDropOffFile: false,
            dropOffFileEntered: null
        }
    }

    changeOrderStatus = (id, action) => {
        //REVIEW: aqui hay un bug si abres el modal de votos y cierras y vuelves a abrir se envia la valoracion automaticamente.
        if (action === 'no_confirm' && !this.state.messageEntered) {
            this.setState({ needMessage: true })
        } else if (action === 'pay') {
            this.props.payMyPendingCredit(getCheckoutUrlByCheckoutId(this.props.order.checkout_id))

        } else if (action === 'vote' && !this.state.votesEntered) {
            this.setState({
                needVotes: true,
                votesEntered: {
                    vote_seller_general: 5,
                    vote_seller_description: 5,
                    vote_seller_packaging: 5,
                    vote_seller_comments: ''
                }
            })
        } else if (action === 'drop_off_ready' && !this.state.dropOffFileEntered) {
            this.setState({
                needDropOffFile: true,
                dropOffFileEntered: null
            })
        } else if (action === 'send' && this.isOrderCertified() && !this.state.trackingNumberEntered) {
            this.setState({ needTrackingNumber: true, trackingNumberEntered: null })
        } else {
            let payload = null
            if (action === 'vote') {
                payload = this.state.votesEntered
            } else if (action === 'no_confirm') {
                payload = this.state.messageEntered
            } else if (action === 'send' && this.isOrderCertified()) {
                payload = { tracking_number: this.state.trackingNumberEntered }
            }
            this.props.changeOrderStatus(id, action, payload)
        }
    }

    uploadDropOffFile = (file) => {
        let filesFormData = new FormData()
        filesFormData.append('dropOffFile', file)

        return this.props.storeOrderDropOffFile(this.props.id, filesFormData)
    }

    isOrderSale = () => {
        return this.props.order.type === 'SALE'
    }

    isOrderBuy = () => {
        return this.props.order.type === 'BUY'
    }

    isOrderCertified = () => {
        return this.props.order?.shipping_method?.type?.toLowerCase() === 'certified'
    }

    isPickup = () => {
        return this.props.order?.shipping_method?.type?.toLowerCase() === 'pickup'
    }

    isPickupBetweenUsers = () => {
        return this.props.order?.shipping_method?.type?.toLowerCase() === 'pickup_between_users'
    }

    render() {
        if (!this.props.order) return <Spinner isLoading={true}></Spinner>

        const showOrderPaymentConfirmationMessageToStore = ((Object.keys(this.props.orderActions?.actions ?? {})[0] ?? null) === 'paid') && this.isPickup()

        return (
            <div className="container-fluid">
                <OrderTitle
                    currentUserId={this.props.userId}
                    buyerId={this.props.order.buyer_id}
                    buyerName={this.props.order.buyer_name}
                    buyerNameSlug={this.props.order.buyer_name_slug}
                    sellerId={this.props.order.seller_id}
                    sellerName={this.props.order.seller_name}
                    sellerNameSlug={this.props.order.seller_name_slug}
                    orderId={this.props.order.id}
                />

                <div className="row mt-1 mb-3">
                    <div className="col-12 col-md-6 card">
                        {this.isPickup() || this.isPickupBetweenUsers() ? (
                            <PickupAddress showInCard={true} address={this.props.order.shipping_address_snapshot} />
                        ) : (
                            <ShippingAddress showInCard={true} address={this.props.order.shipping_address_snapshot} />
                        )}
                        <div style={{ padding: 8 }}>
                            <AlertShippingMethodInstruction
                                isSeller={this.props.userId === this.props.order.seller_id}
                                isBuyer={this.props.userId === this.props.order.buyer_id}
                                shippingMethodType={this.props.order?.shipping_method?.type}
                            ></AlertShippingMethodInstruction>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 card">
                        <div className="card-body">
                            <h5 className="card-title">
                                <Translation id="SUMMARY" defaultMessage="Summary" />
                            </h5>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    <Translation id="ITEMS" defaultMessage="Items" />: {this.props.order.total_articles}
                                </li>
                                {this.isOrderSale() && (
                                    <React.Fragment>
                                        <li className="list-group-item">
                                            <Translation id="ITEMS_COST" defaultMessage="Items cost" />: <DisplayPrice price={this.props.order.total_withouth_shipping_taxes} />
                                        </li>
                                        <li className="list-group-item">
                                            <Translation id="SHIPPING_TAXES_COST" defaultMessage="Shipping taxes cost" />:{' '}
                                            <DisplayPrice price={this.props.order.total_with_shipping_taxes - this.props.order.total_withouth_shipping_taxes} showZero={true} />
                                        </li>

                                        <div
                                            className={
                                                this.props.order.total_with_shipping_taxes_with_discount && this.props.userId == this.props.order.buyer_id
                                                    ? 'list-group-item'
                                                    : 'card-footer'
                                            }
                                            style={{
                                                textDecoration:
                                                    this.props.order.total_with_shipping_taxes_with_discount && this.props.userId == this.props.order.buyer_id
                                                        ? 'line-through'
                                                        : null,
                                            }}
                                        >
                                            <Translation id="TOTAL_WITH_SHIPPING_TAXES_COST" defaultMessage="Total (with shipping taxes)" />:{' '}
                                            <DisplayPrice price={this.props.order.total_with_shipping_taxes} />
                                        </div>
                                        {this.props.order.total_with_shipping_taxes_with_discount && this.props.userId == this.props.order.buyer_id && (
                                            <React.Fragment>
                                                <li className="list-group-item">
                                                    <Translation id="discount_applied" defaultMessage="Discount applied" />:{' '}
                                                    <DisplayPrice price={this.props.order.total_with_shipping_taxes - this.props.order.total_with_shipping_taxes_with_discount} />
                                                </li>
                                                <li className="card-footer">
                                                    <Translation id="TOTAL_WITH_DISCOUNT" defaultMessage="Total (with discount)" />:{' '}
                                                    <DisplayPrice price={this.props.order.total_with_shipping_taxes_with_discount} />
                                                </li>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )}
                                {this.isOrderBuy() && (
                                    <React.Fragment>
                                        <div className="card-footer">
                                            <Translation id="TOTAL_COST" defaultMessage="Total cost" />: <DisplayPrice price={this.props.order.total_with_shipping_taxes} />
                                        </div>
                                    </React.Fragment>
                                )}

                                {this.props.order.partial_refunded && <div className='card-footer'>
                                    <Translation id='PARTIAL_REFUNDED' defaultMessage='Partial refunded' />:{' '}
                                    <DisplayPrice price={this.props.order.partial_refunded.amount} />
                                </div>}

                                <li className="list-group-item mt-2">
                                    <Translation id="shipping_method" defaultMessage="Shipping method" />: <span>{this.props.order.shipping_method?.name ?? ''}</span>
                                </li>

                                {this.props.order.drop_off_file && (
                                    <li className="list-group-item mt-2">
                                        <OrderDropOffFile orderId={this.props.id} fileName={this.props.order.drop_off_file} userToken={this.props.userToken} />
                                    </li>
                                )}

                                {this.isOrderCertified() && (
                                    <li className="list-group-item">
                                        <Translation id="tracking_number" />:{' '}
                                        <a target="_blank" href={`https://www.singpost.com/track-items?trackingid=${this.props.order.tracking_number}`}>
                                            <span className="tracking_number">{this.props.order.tracking_number}</span>
                                        </a>
                                    </li>
                                )}
                            </ul>
                            <div className="row m-1">
                                {this.props.userId === this.props.order.seller_id && (!this.props.order.partial_refunded) &&
                                    <a
                                        className='mb-3 colored-link'
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            this.setState({showRefundModal: true})
                                        }}>
                                <Translation id="want_to_make_a_refund" defaultMessage="Want to make a refund?" />
                                </a>}

                                {this.state.showRefundModal && <PartialRefundModal
                                    isSending={this.props.isSending}
                                    intl={this.props.intl}
                                    order={this.props.order}
                                    onClose={() => this.setState({showRefundModal: false})}
                                    submitPartialRefund={(refundImportEntered) => {
                                        this.props.partialRefund(this.props.id, refundImportEntered)
                                        this.setState({ showRefundModal: false })
                                    }}
                                />}

                                {this.props.orderActions.actions &&
                                    Object.entries(this.props.orderActions.actions).map((action, index) => {
                                        return (
                                            <div className="col" key={index}>
                                                {this.state.needMessage && (
                                                    <Modal
                                                        title={<Translation id="write_your_review" defaultMessage="Make your review"></Translation>}
                                                        onClose={() => this.setState({ needMessage: false })}
                                                    >
                                                        <form
                                                            onSubmit={(event) => {
                                                                event.preventDefault()
                                                                this.changeOrderStatus(this.props.id, action[0])
                                                            }}
                                                            className="form-inline"
                                                        >
                                                            <div className="row form-inline">
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'flex-start',
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    <label htmlFor="reason" style={{ width: '240px' }}>
                                                                        <Translation id="reason_label" defaultMessage="Enter the reason"></Translation>
                                                                    </label>
                                                                    <input
                                                                        id="reason"
                                                                        name="reason"
                                                                        autoComplete="off"
                                                                        type="text"
                                                                        onChange={(e) => this.setState({ messageEntered: e.target.value })}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col">
                                                                    <input
                                                                        className="bt mt-2 mr-2 ml-2"
                                                                        disabled={this.props.isSending}
                                                                        type="submit"
                                                                        value={this.props.intl.formatMessage({ id: 'SUBMIT' })}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </Modal>
                                                )}
                                                {this.state.needDropOffFile && (
                                                    <DropOffFileModal
                                                        isSending={this.props.isSending}
                                                        intl={this.props.intl}
                                                        onClose={() => this.setState({ needDropOffFile: false })}
                                                        uploadFile={(file) => {
                                                            this.uploadDropOffFile(file).then(() => {
                                                                this.setState(
                                                                    { needDropOffFile: false, dropOffFileEntered: file },
                                                                    () => this.changeOrderStatus(this.props.id, action[0])
                                                                )
                                                            })
                                                        }}
                                                        errors={this.props.formErrors}
                                                    />
                                                )}
                                                {this.state.needTrackingNumber && (
                                                    <Modal
                                                        title={<Translation id="write_tracking_number" defaultMessage="Write tracking number"></Translation>}
                                                        onClose={() => this.setState({ needTrackingNumber: false })}
                                                    >
                                                        <form
                                                            onSubmit={(event) => {
                                                                event.preventDefault()
                                                                this.changeOrderStatus(this.props.id, action[0])
                                                            }}
                                                            className="form-inline"
                                                        >
                                                            <div className="row form-inline">
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'flex-start',
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    <label htmlFor="tracking_number" style={{ width: '240px' }}>
                                                                        <Translation id="tracking_number" defaultMessage="Tracking number"></Translation>
                                                                    </label>

                                                                    <input
                                                                        type="text"
                                                                        required
                                                                        id="tracking_number"
                                                                        name="tracking_number"
                                                                        autoComplete="off"
                                                                        onChange={(e) => this.setState({ trackingNumberEntered: e.target.value })}
                                                                    />
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <input
                                                                            className="bt mt-2 mr-2 ml-2"
                                                                            disabled={this.props.isSending}
                                                                            type="submit"
                                                                            value={this.props.intl.formatMessage({ id: 'SUBMIT' })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </Modal>
                                                )}

                                                {this.state.needPayment && (
                                                    <BuyerPayModal
                                                        order={this.props.order}
                                                        credit={this.props.credit}
                                                        onClose={() => this.setState({ needPayment: false })}
                                                        payMyPendingCredit={(url) => this.props.payMyPendingCredit(url)}
                                                    />
                                                )}

                                                {this.state.needVotes && (
                                                    <Modal
                                                        title={<Translation id="write_your_review" defaultMessage="Make your review"></Translation>}
                                                        onClose={() => this.setState({ needVotes: false })}
                                                    >
                                                        <form
                                                            onSubmit={(event) => {
                                                                event.preventDefault()
                                                                this.changeOrderStatus(this.props.id, action[0])
                                                            }}
                                                            className="form-inline"
                                                        >
                                                            <div className="row form-inline">
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'flex-start',
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    <label htmlFor="vote_seller_general" style={{ width: '240px' }}>
                                                                        <Translation id="vote_seller_general_label" defaultMessage="General rating" />
                                                                    </label>
                                                                    <input
                                                                        type="number"
                                                                        min="1"
                                                                        max="5"
                                                                        name="vote_seller_general"
                                                                        autoComplete="off"
                                                                        className="form-control"
                                                                        value={this.state.votesEntered.vote_seller_general}
                                                                        style={{ width: 'auto' }}
                                                                        onChange={(e) =>
                                                                            this.setState({
                                                                                votesEntered: {
                                                                                    ...this.state.votesEntered,
                                                                                    vote_seller_general: e.target.value,
                                                                                },
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'flex-start',
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    <label htmlFor="vote_seller_description" style={{ width: '240px' }}>
                                                                        <Translation id="vote_seller_description_label" defaultMessage="Description rating" />
                                                                    </label>
                                                                    <input
                                                                        type="number"
                                                                        min="1"
                                                                        max="5"
                                                                        name="vote_seller_description"
                                                                        autoComplete="off"
                                                                        className="form-control"
                                                                        value={this.state.votesEntered.vote_seller_description}
                                                                        style={{ width: 'auto' }}
                                                                        onChange={(e) =>
                                                                            this.setState({
                                                                                votesEntered: {
                                                                                    ...this.state.votesEntered,
                                                                                    vote_seller_description: e.target.value,
                                                                                },
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'flex-start',
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    <label htmlFor="vote_seller_packaging" style={{ width: '240px' }}>
                                                                        <Translation id="vote_seller_packaging_label" defaultMessage="Packing rating" />
                                                                    </label>
                                                                    <input
                                                                        type="number"
                                                                        min="1"
                                                                        max="5"
                                                                        name="vote_seller_packaging"
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        value={this.state.votesEntered.vote_seller_packaging}
                                                                        style={{ width: 'auto' }}
                                                                        onChange={(e) =>
                                                                            this.setState({
                                                                                votesEntered: {
                                                                                    ...this.state.votesEntered,
                                                                                    vote_seller_packaging: e.target.value,
                                                                                },
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <label htmlFor="vote_seller_comments">
                                                                        <Translation id="vote_seller_comments_label" defaultMessage="Comments" />
                                                                    </label>
                                                                    <input
                                                                        maxLength="150"
                                                                        name="vote_seller_comments"
                                                                        value={this.state.votesEntered.vote_seller_comments}
                                                                        className="form-control"
                                                                        autoComplete="off"
                                                                        onChange={(e) =>
                                                                            this.setState({
                                                                                votesEntered: {
                                                                                    ...this.state.votesEntered,
                                                                                    vote_seller_comments: e.target.value,
                                                                                },
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <input
                                                                            className="bt mt-2 mr-2 ml-2"
                                                                            disabled={this.props.isSending}
                                                                            type="submit"
                                                                            value={this.props.intl.formatMessage({ id: 'SUBMIT' })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </Modal>
                                                )}

                                                <Button disabled={this.props.isSending} onClick={() => this.changeOrderStatus(this.props.id, action[0])}>
                                                    {this.props.intl.formatMessage({
                                                        id: action[1].label,
                                                    })}
                                                </Button>
                                            </div>
                                        )
                                    })}
                            </div>
                            {showOrderPaymentConfirmationMessageToStore && (
                                <Alerts
                                    alert={
                                        'If you are a store user and the order is pickup, you can validate the payment of the customer, but remember to check the operation with a screenshot or bank movement'
                                    }
                                    status="warning"
                                ></Alerts>
                            )}
                            {this.props.orderActions.no_action && <Alerts alert={this.props.orderActions.no_action.message}></Alerts>}
                            {this.props.order.payload && <Alerts alert={this.props.order.payload} status="warning"></Alerts>}
                        </div>
                    </div>
                </div>
                {this.props.orderActions.no_action && (
                    <React.Fragment>
                        <div className="row mb-3">
                            <div className="col-12 card">
                                <div className="card-body">
                                    <div className="d-md-flex justify-content-between align-items-center">
                                        <div className="votes-info">
                                            <div>
                                                <Translation id="vote_seller_general" defaultMessage="General" />
                                            </div>
                                            {this.props.order && this.props.order.vote_seller_general && <Votes votes={this.props.order.vote_seller_general} />}
                                        </div>
                                        <div className="votes-info">
                                            <div>
                                                <Translation id="vote_seller_descriptio" defaultMessage="Description" />
                                            </div>
                                            {this.props.order && this.props.order.vote_seller_description && <Votes votes={this.props.order.vote_seller_description} />}
                                        </div>
                                        <div className="votes-info">
                                            <div>
                                                <Translation id="vote_seller_packaging" defaultMessage="Packaging" />
                                            </div>
                                            {this.props.order && this.props.order.vote_seller_packaging && <Votes votes={this.props.order.vote_seller_packaging} />}
                                        </div>
                                        <div className="votes-info">
                                            <div>
                                                <Translation id="vote_seller_comments" defaultMessage="Comments" />
                                            </div>
                                            {this.props.order && this.props.order.vote_seller_comments && (
                                                <span className="d-lg-block"> {this.props.order.vote_seller_comments} </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}
                <div className="card-list">
                    {this.props.orderLines &&
                        this.props.orderLines.map((original) => <RenderSaleItemMobile key={original.id} item={original} intl={this.props.intl} showActions={false} />)}
                </div>
            </div>
        )
    }
}

export default injectIntl(Order)
